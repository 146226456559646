import { Container } from "../container";
import { FieldInput, FieldLabel, FieldWrapper, QuestionWrap } from "./styles";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { ErrorHint } from "../error-hint";
import questionMark from "../../assets/images/question.svg";
import React, { FC, useEffect, useState } from "react";
import { StripeCardExpiryElementOptions } from "@stripe/stripe-js";
import { setIn } from "../../common/utils";

const CARD_ELEMENT_OPTIONS: StripeCardExpiryElementOptions = {
  style: {
    base: {
      fontSize: "17px",
      color: "#fff",
      fontFamily: "Matter, sans-serif",
      fontWeight: 600,
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.64)",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

interface Props {
  onSuccess?: (data: { billingDetails: any }) => void;
}

export const CardForm: FC<Props> = ({ onSuccess }) => {
  const [billingDetails, setBillingDetails] = useState({
    address: {
      city: "United Kingdom",
      country: "UK",
      postal_code: "",
    },
    name: "",
  });
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [errorFields, setErrorFields] = useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
    name: true,
  });

  const handleChange = (event) => {
    if (event?.error) {
      setErrorFields((prev) => setIn(prev, event.elementType, true));
      setFieldErrors((prev) =>
        setIn(prev, event.elementType, event.error.message)
      );
    } else if (event?.empty) {
      setErrorFields((prev) => setIn(prev, event.elementType, true));
    } else {
      setFieldErrors((prev) => setIn(prev, event.elementType, ""));
      setErrorFields((prev) => setIn(prev, event.elementType, false));
    }
  };

  const handleChangeName = (event) => {
    const name = event.target.value;
    setBillingDetails((prev) => setIn(prev, "name", name));

    if (name === "") {
      setErrorFields((prev) => setIn(prev, "name", true));
      setFieldErrors((prev) => setIn(prev, "name", "Name is required"));
    } else {
      setErrorFields((prev) => setIn(prev, "name", false));
      setFieldErrors((prev) => setIn(prev, "name", ""));
    }
  };

  useEffect(() => {
    const isFormValid = Object.values(errorFields).every((item) => !item);
    if (isFormValid) {
      onSuccess &&
        onSuccess({
          billingDetails,
        });
    } else {
      onSuccess && onSuccess(null);
    }
  }, [errorFields]);

  return (
    <>
      <Container p="0" mb="24px" direction="column">
        <FieldWrapper>
          <FieldLabel>
            Card number
            <CardNumberElement
              options={{
                ...CARD_ELEMENT_OPTIONS,
                placeholder: "0000 0000 0000 0000",
              }}
              onChange={handleChange}
            />
          </FieldLabel>
          {fieldErrors.cardNumber && (
            <ErrorHint error={fieldErrors.cardNumber} />
          )}
        </FieldWrapper>
      </Container>
      <Container p="0" mb="24px" direction="column">
        <Container direction="row" p="0">
          <FieldWrapper width="140px">
            <FieldLabel>
              Month/Year
              <CardExpiryElement
                options={CARD_ELEMENT_OPTIONS}
                onChange={handleChange}
              />
            </FieldLabel>
          </FieldWrapper>

          <Container direction="row" width="84px" alignItems="center" p="0">
            <FieldWrapper>
              <FieldLabel>
                CVV/CVC
                <CardCvcElement
                  options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "000" }}
                  onChange={handleChange}
                />
              </FieldLabel>
            </FieldWrapper>
            <QuestionWrap>
              <img src={questionMark} alt="Question" />
            </QuestionWrap>
          </Container>
        </Container>

        {(fieldErrors.cardExpiry || fieldErrors.cardCvc) && (
          <ErrorHint
            error={fieldErrors.cardExpiry + " " + fieldErrors.cardCvc}
          />
        )}
      </Container>
      <Container p="0">
        <FieldWrapper>
          <FieldLabel>
            Name
            <FieldInput
              placeholder="JOHN DOE"
              value={billingDetails.name}
              onChange={handleChangeName}
            />
          </FieldLabel>
          {fieldErrors.name && <ErrorHint error={fieldErrors.name} />}
        </FieldWrapper>
      </Container>
    </>
  );
};
