import styled from "styled-components";
import Bg from "../../assets/images/bg.png";

export const Root = styled.div`
  position: relative;
  min-height: 100vh;
  background: url(${Bg}) #5b8f90 no-repeat top center;
  background-size: cover;
`;

export const InnerContainer = styled.div`
  padding-top: 60px;
  height: 100%;
  padding-bottom: 20px;
  max-width: 768px;
  margin: 0 auto;
`;
