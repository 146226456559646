import { Container } from "../../components/container";
import { Typography } from "../../components/typography";
import {
  Root,
  Row,
  CreditCardIcon,
  Logo,
  FieldWrapper,
  FieldLabel,
  FieldInput,
  StyledLabel,
  StyledInput,
  QuestionWrap,
  PaymentRequestButtonWrap,
} from "./styles";
import Visa from "../../assets/images/visa.png";
import MasterCard from "../../assets/images/mastercard.png";
import Amex from "../../assets/images/amex.png";
import Discover from "../../assets/images/discover.png";
import { Button } from "../../components/button";
import React, { useEffect, useState } from "react";
import {
  useClientMeMutation,
  useCreateClientPaymentMethodsMutation,
  useGetStripeClientSecretMutation,
} from "../../common/store";
import {
  Elements,
  CardNumberElement,
  useElements,
  useStripe,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeCardExpiryElementOptions } from "@stripe/stripe-js";
import { generateUrl, isEmptyObject, setIn } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../common/hooks/useSearchParams";
import { ErrorHint } from "../../components/error-hint";
import { has, get } from "lodash";
import { ROUTE_CLIENT_MANAGE_CARDS, STRIPE_KEY } from "../../common/constants";
import questionMark from "../../assets/images/question.svg";

const stripePromise = loadStripe(STRIPE_KEY);

const CARD_ELEMENT_OPTIONS: StripeCardExpiryElementOptions = {
  style: {
    base: {
      fontSize: "17px",
      color: "#fff",
      fontFamily: "Matter, sans-serif",
      fontWeight: 600,
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.64)",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const SetupForm = ({ clientSecret }) => {
  const [getMe] = useClientMeMutation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [billingDetails, setBillingDetails] = useState({
    address: {
      city: "United Kingdom",
      country: "UK",
      postal_code: "",
    },
    name: "",
  });
  const elements = useElements();
  const { state, backUrl } = useSearchParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMe({})
      .unwrap()
      .then((res: any) => {
        const fullName = get(res, "fullName");
        handleFieldChange("name", fullName);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!billingDetails.name) {
      setFieldErrors((prev) => setIn(prev, "name", "Required field"));
    }
    if (!billingDetails.address.postal_code) {
      setFieldErrors((prev) =>
        setIn(prev, "address.postal_code", "Required field")
      );
    }

    if (!isEmptyObject(fieldErrors)) {
      return;
    }

    setIsLoading(true);

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: billingDetails,
        },
      })
      .then((result) => {
        if (result?.error) {
          setErrorMessage(result.error.message);
        } else {
          navigate(generateUrl(ROUTE_CLIENT_MANAGE_CARDS, { state, backUrl }));
        }
      })
      .catch(({ error }) => {
        setErrorMessage(error?.message || "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    if (event?.error) {
      setFieldErrors((prev) =>
        setIn(prev, event.elementType, event.error.message)
      );
    } else {
      setFieldErrors((prev) => setIn(prev, event.elementType, ""));
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setBillingDetails((prevState) => setIn(prevState, fieldName, value));
    setFieldErrors((prev) =>
      setIn(prev, fieldName, value ? "" : "Required field")
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        direction="column"
        bgColor="#212322"
        borderRadius="12px"
        p="24px"
      >
        <Container p="0" mb="24px" direction="column">
          <FieldWrapper>
            <FieldLabel>
              Card number
              <CardNumberElement
                options={{
                  ...CARD_ELEMENT_OPTIONS,
                  placeholder: "0000 0000 0000 0000",
                }}
                onChange={handleChange}
              />
            </FieldLabel>
            {fieldErrors.cardNumber && (
              <ErrorHint error={fieldErrors.cardNumber} />
            )}
          </FieldWrapper>
        </Container>
        <Container p="0" mb="24px" direction="column">
          <Container direction="row" p="0">
            <FieldWrapper width="140px">
              <FieldLabel>
                Month/Year
                <CardExpiryElement
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={handleChange}
                />
              </FieldLabel>
            </FieldWrapper>

            <Container direction="row" width="84px" alignItems="center" p="0">
              <FieldWrapper>
                <FieldLabel>
                  CVV/CVC
                  <CardCvcElement
                    options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "000" }}
                    onChange={handleChange}
                  />
                </FieldLabel>
              </FieldWrapper>
              <QuestionWrap>
                <img src={questionMark} alt="Question" />
              </QuestionWrap>
            </Container>
          </Container>

          {(fieldErrors.cardExpiry || fieldErrors.cardCvc) && (
            <ErrorHint
              error={fieldErrors.cardExpiry + " " + fieldErrors.cardCvc}
            />
          )}
        </Container>
        <Container p="0">
          <FieldWrapper>
            <FieldLabel>
              Name
              <FieldInput
                placeholder="JOHN DOE"
                value={billingDetails.name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
              />
            </FieldLabel>
            {fieldErrors.name && <ErrorHint error={fieldErrors.name} />}
          </FieldWrapper>
        </Container>
      </Container>

      {errorMessage && (
        <Container mt="16px" justifyContent="center">
          <Typography variant="normal" color="#df1b41" textAlign="center">
            {errorMessage}
          </Typography>
        </Container>
      )}

      <Container mt="24px" direction="column" p="0">
        <StyledLabel>
          Postal code
          <StyledInput
            onChange={(e) =>
              handleFieldChange("address.postal_code", e.target.value)
            }
          />
        </StyledLabel>
        {has(fieldErrors, "address.postal_code") && (
          <ErrorHint error={get(fieldErrors, "address.postal_code", "")} />
        )}
      </Container>
      <Container mt="24px" p="0">
        <Button disabled={!stripe} loading={isLoading} type="submit">
          Confirm payment method
        </Button>
      </Container>
    </form>
  );
};

const SetupWallets = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>();
  const [createPaymentMethod] = useCreateClientPaymentMethodsMutation();
  const { state, backUrl } = useSearchParams();

  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "Alacart",
        amount: 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: false,
      disableWallets: ["link"],
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          setPaymentRequest(pr);
          pr.on("paymentmethod", (ev) => {
            const paymentMethodId = get(ev, "paymentMethod.id", "");

            createPaymentMethod({
              paymentMethodId,
            })
              .unwrap()
              .then(() => {
                ev.complete("success");

                navigate(
                  generateUrl(ROUTE_CLIENT_MANAGE_CARDS, { state, backUrl })
                );
              })
              .catch((err) => {
                ev.complete("fail");
              });
          });
        }
      })
      .catch((err) => {
        //log errors, retry, or abort here depending on your use case
      });
  }, [stripe]);

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonWrap>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: { paymentRequestButton: { height: "52px" } },
            }}
            //hacky fix to make force this component to re-render
            //a la https://github.com/stripe/react-stripe-elements/issues/284
            key={Math.random()}
          />
        </PaymentRequestButtonWrap>
      )}
    </>
  );
};

export const ClientStripeAddNewCardContainer = () => {
  const [getClientSecret] = useGetStripeClientSecretMutation();
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    getClientSecret({})
      .unwrap()
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Root>
      <Container direction="column">
        <Logo />
      </Container>
      {clientSecret && (
        <>
          <Container direction="column" mt="24px">
            <Typography variant="large" color="#fff">
              Please enter your credit card information
            </Typography>
          </Container>
          <Container mt="24px" direction="column">
            <Elements
              stripe={stripePromise}
              options={{
                fonts: [
                  {
                    family: "Matter",
                    src: 'local("Matter"), local("Matter"), url(https://alacart.iota.diffco.us/fonts/matter/Matter-SemiBold.woff2) format("truetype")',
                  },
                ],
              }}
            >
              <SetupWallets />
              <SetupForm clientSecret={clientSecret} />
            </Elements>
          </Container>
        </>
      )}

      <Container direction="column" mt="24px">
        <Row>
          <CreditCardIcon src={Visa} />
          <CreditCardIcon src={MasterCard} />
          <CreditCardIcon src={Discover} />
          <CreditCardIcon src={Amex} />
        </Row>
      </Container>
      <Container direction="column" mt="48px" textAlign="center">
        <Typography variant="small" color="#ffffff">
          We accept payment by Visa, Mastercard, Discover, and American Express.
          All payments are securely processed through Stripe (visit
          https://www.stripe.com for more information). Alacart does not store
          any payment details. PCI-DSS encryption requirements help ensure the
          secure handling of credit card information by Alacart and its service
          providers.
        </Typography>
      </Container>
    </Root>
  );
};
