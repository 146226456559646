import { useEffect } from "react";
import { useSegment } from "../segment";
import { useRouteError } from "react-router-dom";
import { EmptyPage } from "../empty-page";
import { Container } from "../container";
import { NotificationBox } from "../notification-box";

export const RootBoundary = () => {
  let error: any = useRouteError();
  const segment = useSegment();

  useEffect(() => {
    segment.track("frontend_error", {
      page: window.location.pathname,
      error: error.message,
    });
  }, []);

  return (
    <EmptyPage
      content={
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="8vh"
        >
          <NotificationBox
            title="Error"
            content="Something went wrong. Please try again later."
          />
        </Container>
      }
    />
  );
};
