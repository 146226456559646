import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;

export const StyledRadio = styled.img``;
