import { FC } from "react";
import { Root, StyledLabel, StyledInput } from "./styles";

interface Props {
  name?: string;
  label: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export const Input: FC<Props> = ({
  name,
  label,
  onChange,
  value,
  placeholder,
}) => {
  return (
    <Root>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput {...{ name, onChange, value, placeholder }} />
    </Root>
  );
};
