import { FC } from "react";
import { Root } from "./styles";
import { IconRadio, IconRadioChecked } from "../icon";

interface Props {
  id?: string;
  checked?: boolean;
}

export const Radio: FC<Props> = ({ id, checked = false }) => {
  return <Root>{checked ? <IconRadioChecked /> : <IconRadio />}</Root>;
};
