import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    fetchOneOrder: builder.query<{ name: string; id: number }[], { id: any }>({
      query: ({ id, ...params }) => ({
        url: `/api/v1/orders/public/${id}`,
        method: "GET",
        params,
      }),
    }),

    getOrderPaymentIntent: builder.mutation<any, Record<string, string>>({
      query: ({ id }) => ({
        url: `/api/v1/orders/${id}/payment-intent`,
        method: "POST",
      }),
    }),
  }),
});

export const { useFetchOneOrderQuery, useGetOrderPaymentIntentMutation } =
  orderApi;
