import { FC } from "react";
import { Root } from "./styles";

interface Props {
  children: React.ReactNode;
  fontFamily?: string;
  variant: string;
  fontWeight?: string;
  textAlign?: string;
  textDecoration?: string;
  color?: string;
  as?: "p" | "h1" | "h2" | "h3" | "h4" | "span" | "div";
  onClick?: () => void;
}

export const Typography: FC<Props> = ({
  variant,
  fontFamily,
  fontWeight,
  children,
  textAlign,
  textDecoration,
  color,
  as,
  onClick,
}) => {
  return (
    <Root
      onClick={onClick}
      variant={variant}
      fontWeight={fontWeight}
      color={color}
      fontFamily={fontFamily}
      textAlign={textAlign}
      textDecoration={textDecoration}
      as={as}
    >
      {children}
    </Root>
  );
};
