import React, { FC } from "react";
import { Root, RootContainer, RootBottomContainer } from "./styles";
import CardsIcon from "../../assets/images/cards.svg";
import { Radio } from "../radio";
import { CardForm } from "../card-form";
import { Typography } from "../typography";
import clsx from "clsx";

interface Props {
  title?: string;
  checked?: boolean;
  onClick?: () => void;
  clientSecret?: string;
  onSuccess?: (data: { billingDetails: any }) => void;
  disabled?: boolean;
}

export const RadioCardButton: FC<Props> = ({
  title,
  checked,
  onClick,
  onSuccess,
  disabled,
}) => {
  const handleClick = () => {
    if (disabled) return;

    onClick && onClick();
  };
  return (
    <Root
      className={clsx({
        disabled: disabled,
      })}
    >
      <RootContainer onClick={handleClick}>
        <Radio checked={checked} />
        {title ? (
          <Typography variant="large" color="#fff">
            {title}
          </Typography>
        ) : (
          <img src={CardsIcon} alt="Cards" />
        )}
      </RootContainer>
      <RootBottomContainer display={checked ? "block" : "none"}>
        <CardForm onSuccess={onSuccess} />
      </RootBottomContainer>
    </Root>
  );
};
