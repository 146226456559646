import { PageTitle, Content, SubTitle, BackButton } from "./styles";
import { Container } from "../../components/container";
import { IconBack } from "../../components/icon";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const PrivacyPolicyContainer = () => {
  useReportPageLoadMetrics();

  return (
    <>
      <Container>
        <BackButton
          onClick={() => {
            window.history.back();
          }}
        >
          <IconBack />
        </BackButton>
      </Container>
      <Container mt="32px" direction="column">
        <PageTitle>Privacy Policy</PageTitle>

        <i>Effective Date: 10 february, 2023</i>
        <SubTitle>1. INTRODUCTION</SubTitle>
        <Content>
          <p>
            Alacart International Limited{" "}
            <b>(“us”, “we”, “our”, or “Alacart”)</b> operates the Alacart mobile
            applications, WhatsApp chatbot <b>(“Chatbot”)</b>, and website
            (www.alacart.me) (collectively, the “Services”). Our Services
            facilitate relationships between venues and their customers. Alacart
            enables its users to make payments and carry out other interactions
            with venues via WhatsApp — an instant messaging application owned
            and developed by Meta Platforms, Inc. — and its other Services.
          </p>
          <p>
            Alacart International Limited, based at 3rd Floor, 1 Ashley Road,
            Altrincham, Cheshire, WA14 2DT, is the controller of your personal
            data, and the protection and security of your personal data are
            important to us. If you have any questions about this User Privacy
            Policy, including any requests to exercise your legal rights, please
            contact us using the details set out in section 12 below.
          </p>
          <p>
            If you had intended to learn more about how we handle personal data
            relating to Alacart corporate partners and their representatives,
            please click here [LINK] to see our Partner Privacy Policy.
          </p>
        </Content>
        <SubTitle>2. PERSONAL DATA COLLECTION</SubTitle>
        <Content>
          <p>
            Personal data means any information relating to an identified or
            identifiable individual. We collect or otherwise process the
            following categories of personal data:
          </p>
          <ul>
            <li>
              <b>Contact:</b> If you contact us through our Services, you may
              provide us with your name, email address, phone number, or other
              contact information to respond to you and resolve your request. We
              will also collect this information when you set up an account with
              us. You may also provide us with the content of your
              communications that take place using our Services.
            </li>
            <li>
              <b>Payment:</b> When you first set up an account with us or
              otherwise use our Services, you may also provide us with
              information necessary to facilitate payments (e.g., card details
              and postal code). This information, in addition to Alacart
              transaction information (e.g., the venue’s name and location,
              order details, amount charged, and payment method), will also be
              processed when you make an order using our Chatbot and for the
              purposes of managing and facilitating reservations, payments,
              fees, and charges.
              <br />
              Payments on our Services are handled by Stripe, and the
              information you provide to Stripe in connection with your payment
              information and transactions is handled in accordance with
              Stripe’s Terms of Service and Privacy Policy. Your payment data is
              stored by Stripe. For more information, please read Stripe’s
              Services Agreement{" "}
              <a href="https://stripe.com/ssa" target="_blank" rel="noreferrer">
                here
              </a>{" "}
              and Stripe’s Privacy Policy{" "}
              <a
                href="https://stripe.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </li>
            <li>
              <b>Social media:</b> If you interact with our pages on social
              media platforms, such as Facebook, LinkedIn, or Twitter, you or
              the platforms may provide us with information through the
              platform.
            </li>
            <li>
              <b>Activity:</b> When you visit, use, and interact with the
              Services, we may collect certain data relating to your visit, use,
              and/or interactions. This information includes:
            </li>
            <li>
              <b>Orders:</b> Information about the orders you make within
              venues, such as the food and drink you have ordered, so we or
              Alacart Partners can provide you with recommendations or select
              benefits.
            </li>
            <li>
              <b>Searches:</b> Information about the venues you search so that
              we can provide you with information on other venues that match
              your preferences.
            </li>
            <li>
              <b>Log and Usage Data:</b> Information that your browser or device
              automatically sends whenever you use our Services. Log data
              includes your Internet Protocol address, browser type and
              settings, the date and time of your request, and the nature of
              your interactions with our Services.
            </li>
            <li>
              <b>Cookie Data:</b> Please see our Cookie Policy [Link] to learn
              more about how we use cookies.
            </li>
            <li>
              <b>Technical Data:</b> The type of the device you are using and
              related information, e.g., device model, operating system, device
              identifiers, and the browser you are using.
            </li>
          </ul>
        </Content>

        <SubTitle>3. HOW WE USE PERSONAL DATA</SubTitle>
        <Content>
          <p>
            We will only ever process your personal data where there is a
            legitimate reason for doing so and that reason is permitted under
            applicable laws.
          </p>
          <table>
            <thead>
              <tr>
                <th>PURPOSE</th>
                <th>LEGAL BASIS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  To operate and administer our Services <br />
                  This includes: providing and maintaining the functionality of
                  the Services; responding to your inquiries or feedback;
                  managing our relationship with you, including sending you
                  administrative information relating to our Services; and
                  carrying out obligations arising from our contract with you.
                </td>
                <td>
                  • Performance of a contract or to take steps to enter into a
                  contract at your request <br />
                  • Our legitimate interests <br />• Where it is necessary to
                  comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To improve, monitor, and protect our Services
                  <br />
                  This includes: (i) improving and developing the Services, and
                  developing our business and marketing strategy; (ii)
                  aggregating personal data and using the aggregated information
                  to analyse the effectiveness of our Services, to improve and
                  add features to our Services, and for other similar purposes;
                  (iii) preventing fraud, criminal activity, or misuse of our
                  Services, and ensuring the security and functioning of our IT
                  systems, architecture, and networks (including
                  troubleshooting, testing, system maintenance, support, and
                  hosting of data); and (iv) asking you to leave a review or
                  take a survey.
                </td>
                <td>
                  • Our legitimate interests
                  <br /> • Where it is necessary to comply with a legal
                  obligation
                  <br /> • Consent
                </td>
              </tr>
              <tr>
                <td>
                  To personalise our Services
                  <br />
                  This includes: analysing how you interact with the Services
                  (e.g., your purchasing choices and trends, and your
                  reservation habits) and personalising your experience on that
                  basis. For example, we or Alacart Partners may provide you
                  with bespoke recommendations or select benefits. Such benefits
                  may depend on your user status.
                </td>
                <td>• Our legitimate interests</td>
              </tr>
              <tr>
                <td>
                  To deliver and monitor content and advertisements relevant to
                  our users
                  <br />
                  We may contact you to tell you about information we believe
                  will be of interest to you, such as information about products
                  and services, features, surveys, newsletters, and upcoming
                  events. We will also monitor the effectiveness and
                  distribution of this advertising. You may opt out of receiving
                  promotional emails by following the instructions contained in
                  each communication we send to you or by contacting us as
                  explained at the bottom of this User Privacy Policy. If you
                  unsubscribe from our marketing lists, you will no longer
                  receive marketing communications, but we will continue to
                  contact you regarding administrative matters, and to respond
                  to your requests.
                </td>
                <td>
                  • Our legitimate interests
                  <br />• Consent
                </td>
              </tr>
              <tr>
                <td>
                  To enforce our agreements, to comply with legal obligations,
                  and to defend us against legal claims or disputes
                  <br />
                  We may use your personal data, where it is in our legitimate
                  business interests, to: enforce and comply with our terms and
                  policies; protect our rights, privacy, safety, or property;
                  ensure the integrity of our Services; defend ourselves against
                  legal claims or disputes; and recover payments due to us. Some
                  processing may also be necessary to comply with a legal
                  obligation, for example to keep records of transactions, or as
                  requested by any judicial process or governmental agency.
                </td>
                <td>
                  • Our legitimate interests
                  <br />• Where it is necessary to comply with a legal
                  obligation
                </td>
              </tr>
            </tbody>
          </table>
        </Content>

        <SubTitle>4. HOW WE SHARE AND DISCLOSE PERSONAL DATA</SubTitle>
        <Content>
          <p>
            We do not sell personal data or share personal data with third
            parties for targeted advertising. We may share the categories of
            personal data described above with the following categories of third
            parties:
          </p>
          <ul>
            <li>
              <b>Vendors, and Service Providers:</b> To assist us in meeting
              business operational needs and to perform certain services and
              functions, we may share personal data with our vendors and service
              providers, including providers of hosting services, cloud
              services, and other information technology services providers,
              event management services, email communication software and email
              newsletter services, advertising and marketing services, customer
              relationship management and customer support services, and web
              analytics services. Pursuant to our instructions, these parties
              will access, process, or store personal data in the course of
              performing their duties for us.
            </li>
            <li>
              <b>Alacart Partners:</b> We may also share personal data with
              Alacart Partners where it is required to facilitate the
              performance of our services. For example, to enhance your
              experience at the venue, we may share your preferences (based on
              your order history) with Alacart Partners.
            </li>
            <li>
              <b>Business Transfers:</b> If we are involved in a merger,
              acquisition, financing due diligence, reorganisation, bankruptcy,
              receivership, sale of all or a portion of our assets, or
              transition of service to another provider (collectively, a
              “Transaction”), your personal data and other information may be
              shared in the due diligence process with counterparties and others
              assisting with the Transaction and transferred to a successor or
              affiliate as part of that Transaction along with other assets.
            </li>
            <li>
              <b>Legal Requirements:</b> If required to do so by law or in our
              good faith belief that such action is necessary, we may share
              personal data to: (i) comply with a legal obligation, including to
              meet national security or law enforcement requirements; (ii)
              protect and defend our rights or property; (iii) prevent fraud;
              (iv) act in urgent circumstances to protect the personal safety of
              users of the Site, or the public; or (v) protect against legal
              liability.
            </li>
            <li>
              <b>Affiliates:</b> We may share personal data with our current and
              future affiliates, meaning an entity that controls, is controlled
              by, or is under common control with, Alacart International
              Limited. Our affiliates will use the personal data we share in a
              manner consistent with this User Privacy Policy.
            </li>
          </ul>
        </Content>

        <SubTitle>5. RETENTION OF PERSONAL DATA</SubTitle>
        <Content>
          <p>
            We generally retain your personal data only for as long as necessary
            to fulfil the purposes outlined in this User Privacy Policy. This
            will generally be for the duration of time you interact with us;
            however, there may be situations where we retain your personal data
            for a longer or shorter period.
          </p>
          <p>
            When determining the relevant retention periods for your personal
            data, we take into account the following factors: (a) any
            permissions you give us with regards to your personal data; (b) our
            contractual obligations and rights in relation to the personal data
            involved; (c) our legal obligations under relevant laws to retain
            data for a certain period of time; (d) our legitimate business and
            commercial interests; (e) whether retention is advisable in light of
            our legal position (such as with regard to applicable statute of
            limitations, investigations, litigation, and other potential and
            actual disputes); and (f) any guidelines issued by relevant data
            protection authorities.
          </p>
          <p>
            Note, in some circumstances, we may anonymise your personal data
            such that it can no longer be associated with you.
          </p>
        </Content>

        <SubTitle>6. CHILDREN</SubTitle>
        <Content>
          <p>
            Our Services are not directed to children who are under the age of
            18. We do not knowingly collect personal data from children under
            the age of 18. If you have reason to believe that a child under the
            age of 18 has provided personal data to us through our Services,
            please contact us and we will endeavor to delete that information
            from our databases.
          </p>
        </Content>

        <SubTitle>7. LINKS TO OTHER WEBSITES</SubTitle>
        <Content>
          <p>
            Our Services may link to other websites not operated or controlled
            by us, including social media services (“Third Party Sites”). The
            information that you share with Third Party Sites will be governed
            by the specific privacy policies and terms of service of the Third
            Party Sites and not by this User Privacy Policy. By providing these
            links, we do not imply that we endorse or have reviewed these sites.
            Please contact the Third Party Sites directly for information on
            their privacy practices and policies.
          </p>
        </Content>
        <SubTitle>8. SECURITY</SubTitle>
        <Content>
          <p>
            We implement commercially reasonable technical, administrative, and
            organisational measures to protect personal data both online and
            offline from loss, misuse, and unauthorised access, disclosure,
            alteration, or destruction. However, no Internet or email
            transmission is ever fully secure or error free. Therefore, you
            should take special care in deciding what information you send to us
            via the Services or email. Please keep this in mind when disclosing
            any personal data to us via the internet. In addition, we are not
            responsible for circumvention of any privacy settings or security
            measures contained on the Services, or third party websites.
          </p>
        </Content>
        <SubTitle>9. INTERNATIONAL TRANSFERS</SubTitle>
        <Content>
          <p>
            We are based in the United Kingdom. We may also share your personal
            data with our affiliates and service providers outside of the United
            Kingdom. Such countries may not have laws that provide the same
            level of protection for your personal data as in your country of
            residence. When we share your personal data, we endeavor to keep it
            confidential and secure. For example, where required under EEA and
            UK data protection laws, we will implement appropriate safeguards,
            such as European Commission-approved standard contractual clauses
            and/or UK government-approved international data transfer
            agreements. Please contact us using the details in the “Contact Us”
            section below if you require further information on our
            international transfers.
          </p>
        </Content>

        <SubTitle>10. YOUR CHOICES</SubTitle>
        <Content>
          <p>
            <b>Unsubscribe from direct marketing communications.</b> You may opt
            out of marketing-related communications by following the opt out or
            unsubscribe instructions contained in the marketing communication we
            send you.
          </p>
          <p>
            <b> Personal data requests.</b> We may offer you choices that affect
            how we handle the personal data that we control. Where required
            under the laws that apply in your place of residence, you may
            request the following in relation to your personal data:
          </p>
          <ul>
            <li>
              <b>Information:</b> You may request information about how we have
              collected and used your personal data. We have made this
              information available to you without having to request it by
              including it in this User Privacy Policy.
            </li>
            <li>
              <b>Access:</b> If you ask us, we will confirm whether we are
              processing your personal data and, if so, provide you with a copy
              of that personal data along with certain other details. If you
              require additional copies, we may need to charge a reasonable fee.
            </li>
            <li>
              <b>Data Portability:</b> You may request to receive personal data
              in a structured, commonly used and machine-readable format and (if
              certain conditions are satisfied), transmit that personal data to
              a third party, if we have the technical means.
            </li>
            <li>
              <b>Withdraw Consent:</b> In circumstances where you have provided
              your consent to the collection, processing and transfer of your
              personal data for a specific purpose, you have the right to
              withdraw consent for that specific processing at any time.
            </li>
            <li>
              <b>Objection/Restriction:</b> You may also ask us to restrict or
              ‘block’ the processing of your personal data in certain
              circumstances, such as where you contest its accuracy or object to
              us processing it. If you exercise your rights, we will respect
              your decision in accordance with our legal obligations.
            </li>
            <li>
              <b>Correction:</b> You may request the correction of personal data
              that is inaccurate or out of date.
            </li>
            <li>
              <b>Deletion:</b> You may requestion the deletion of personal data
              that we no longer need to provide the Services or for other lawful
              purposes.
            </li>
          </ul>

          <p>
            To make a request, please use the contact details provided in the
            “Contact Us” section below.
          </p>
          <p>
            All requests must be made by you or someone legally authorised to
            act on your behalf. We must be able to verify your identity and
            confirm that the personal data relates to you in order to respond to
            your request and/or provide you with personal data.
          </p>
          <p>
            In some instances, your choices and rights may be limited, such as
            where fulfilling your request would impair the rights of others, our
            ability to provide a service you have requested, or our ability to
            comply with our legal obligations and enforce our legal rights.
          </p>
          <p>
            Please note, if you have a concern about our privacy practices,
            including the way we handle your personal data, you can report it to
            your local data protection authority; in the UK, this is the
            Information Commissioner’s Office (ICO). We hope you will be able to
            address any concerns with us directly in the first instance so we
            are able to respond to them.
          </p>
          <p>
            Do Not Track. Some Internet browsers may be configured to send “Do
            Not Track” signals to the online services that you visit. We
            currently do not respond to “Do Not Track” or similar signals. To
            find out more about “Do Not Track,” please visit{" "}
            <a href="http://www.allaboutdnt.com.">
              http://www.allaboutdnt.com.
            </a>
          </p>
        </Content>

        <SubTitle>11. CHANGES TO THE PRIVACY POLICY</SubTitle>
        <Content>
          <p>
            The Services and our business may change from time to time. As a
            result we may change this User Privacy Policy at any time. When we
            do, we will post an updated version, unless another type of notice
            is required by the applicable law.
          </p>
        </Content>

        <SubTitle>12. CONTACT US</SubTitle>
        <Content>
          <p>
            Alacart International Limited is the controller of your personal
            data. If you have any questions about our User Privacy Policy or
            information practices, please feel free to contact us at{" "}
            <a href="mailto:support@alacart.me">support@alacart.me</a> or via
            the Chatbot.
          </p>
          <address>
            3rd Floor, 1 Ashley Road
            <br />
            Altrincham
            <br />
            Cheshire
            <br />
            WA14 2DT
          </address>
        </Content>
        <Content />
      </Container>
    </>
  );
};
