import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../../components/container";
import { Button } from "../../components/button";
import { Typography } from "../../components/typography";
import {
  Root,
  Logo,
  Row,
  TableHeader,
  TableRow,
  TableFooter,
  Divider,
  Col,
  PageHeader,
  Badge,
  ManagePaymentMethodButton,
  ManagePaymentMethodButtonContainer,
} from "./styles";
import {
  useGetClientOrderQuery,
  useGetDefaultClientPaymentMethodMutation,
} from "../../common/store";
import { NotificationBox } from "../../components/notification-box";
import { get, map, isEmpty } from "lodash";
import {
  encodeValueChars,
  formatPrice,
  generateUrl,
  smsLink,
  tryParseJSON,
  whatsappLink,
} from "../../common/utils";
import {
  ROUTE_CLIENT_MANAGE_CARDS,
  SMS_PHONE_NUMBER,
  STATE_AUTO_CHARGE_ERROR,
  STATE_WITH_BACK,
} from "../../common/constants";
import { BackToMessenger } from "../../components/back-to-messenger";
import { useUrlMsgr } from "../../common/hooks/useUrlMsgr";
import { useSearchParams } from "../../common/hooks/useSearchParams";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const ClientBillContainer = () => {
  const navigate = useNavigate();
  useReportPageLoadMetrics();
  const { state } = useSearchParams();
  const { id } = useParams();
  const [
    getDefaultPaymentMethod,
    { data: paymentMethod, isLoading: paymentMethodIsLoading },
  ] = useGetDefaultClientPaymentMethodMutation();
  const [pollingInterval, setPollingInterval] = useState(3000);
  const msrg = useUrlMsgr();
  const { data, isLoading } = useGetClientOrderQuery(
    { id },
    {
      pollingInterval,
    }
  );
  const items = get(data, "items", []);
  const restaurant = get(data, "restaurant");

  useEffect(() => {
    if (data && data.status === "closed") {
      setPollingInterval(0);
    } else if (data && data.isPaid) {
      setPollingInterval(0);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.autoChargeError && state !== "success") {
      const error = tryParseJSON(data.autoChargeError);

      navigate(
        generateUrl(ROUTE_CLIENT_MANAGE_CARDS, {
          state: STATE_AUTO_CHARGE_ERROR,
          order: id,
          ...get(error, "response.card", {}),
        })
      );
    }
  }, [data, state]);

  useEffect(() => {
    getDefaultPaymentMethod({});
  }, []);

  const handleDispute = () => {
    const message = encodeValueChars(
      `Hi, I’d like to dispute my bill OrderID:${id}!`
    );

    let link = whatsappLink(SMS_PHONE_NUMBER, message);

    if (msrg === "sms") {
      link = smsLink(SMS_PHONE_NUMBER, message);
    }

    window.open(link, "_blank");
  };

  const handleManagePaymentMethod = () => {
    navigate(
      generateUrl(ROUTE_CLIENT_MANAGE_CARDS, {
        state: STATE_WITH_BACK,
      })
    );
  };

  if (isLoading || paymentMethodIsLoading) {
    return (
      <Root>
        <PageHeader>
          <Container direction="column">
            <Logo />
          </Container>
        </PageHeader>
        <Container mt="24px" direction="column">
          <NotificationBox content="Loading, please wait..." />
        </Container>
      </Root>
    );
  }

  if (!isLoading && !data) {
    return (
      <Root>
        <PageHeader>
          <Container direction="column">
            <Logo />
          </Container>
        </PageHeader>
        <Container mt="24px" direction="column">
          <NotificationBox content="No active order" />
        </Container>
      </Root>
    );
  }

  const totalTax = get(data, "totalTax", 0);
  const total = get(data, "total") || 0;
  const taxPercent = total ? (totalTax / total) * 100 : 0;
  const totalServiceCharges = get(data, "totalServiceCharges", 0);
  // const servicePercent = total ? (totalServiceCharges / total) * 100 : 0;
  const servicePercent = 15;
  const totalDiscounts = get(data, "totalDiscounts", 0);
  const totalCredit = get(data, "totalCredit", 0);
  const billTotal = total - totalCredit;

  const isBillRequested = !!get(data, "billRequestedAt");

  const card = get(paymentMethod, "card");
  const cardLast4 = get(card, "last4");
  const cardBrand = get(card, "brand");

  return (
    <Root>
      <PageHeader>
        <Container direction="column">
          <Logo />
        </Container>
        <Container direction="column" mt="24px">
          <Typography variant="large" color="#fff">
            Your {isBillRequested ? "final bill" : "live bill"} in{" "}
            <Typography
              variant="large"
              color="#fff"
              fontFamily="Matter, sans-serif"
              fontWeight="bold"
              as="span"
            >
              {restaurant?.name}
            </Typography>
          </Typography>
        </Container>
      </PageHeader>
      <Container mt="24px" direction="column">
        <TableHeader>
          <Col width="45%">ITEM</Col>
          <Col width="9%">QT</Col>
          <Col width="23%">PRICE</Col>
          <Col width="23%">AMOUNT</Col>
        </TableHeader>
        <Divider />
        {isEmpty(items) ? (
          <TableRow>
            <Col width="100%">
              <Typography variant="medium" color="#000" textAlign="center">
                Items not added yet
              </Typography>
            </Col>
          </TableRow>
        ) : (
          map(items, (item) => (
            <TableRow key={item.id}>
              <Col width="45%">{item.name}</Col>
              <Col width="9%">{item.quantity}</Col>
              <Col width="23%">{formatPrice(item.price / item.quantity)}</Col>
              <Col width="23%">{formatPrice(item.price)}</Col>
            </TableRow>
          ))
        )}
        <Divider />
        {totalTax ? (
          <TableRow>
            <Col width="45%">Tax</Col>
            <Col width="9%"></Col>
            <Col width="23%">{taxPercent.toFixed(2)}%</Col>
            <Col width="23%">{formatPrice(totalTax)}</Col>
          </TableRow>
        ) : null}
        {totalServiceCharges ? (
          <TableRow>
            <Col width="45%">Service fee</Col>
            <Col width="9%"></Col>
            <Col width="23%">{servicePercent.toFixed(2)}%</Col>
            <Col width="23%">{formatPrice(totalServiceCharges)}</Col>
          </TableRow>
        ) : null}

        {totalDiscounts ? (
          <TableRow>
            <Col width="45%">Discounts</Col>
            <Col width="9%"></Col>
            <Col width="23%"></Col>
            <Col width="23%">-{formatPrice(totalDiscounts)}</Col>
          </TableRow>
        ) : null}

        {totalCredit ? (
          <TableRow>
            <Col width="45%">Credit</Col>
            <Col width="9%"></Col>
            <Col width="23%"></Col>
            <Col width="23%">-{formatPrice(totalCredit)}</Col>
          </TableRow>
        ) : null}

        <Divider />
        <TableFooter>
          <Col>Total</Col>
          <Col>
            {data.isPaid && <Badge>Paid</Badge>}
            {!data.isPaid && data.status === "closed" && <Badge>Closed</Badge>}
            {formatPrice(billTotal)}
          </Col>
        </TableFooter>
      </Container>
      {!isBillRequested && (
        <Container mt="24px" direction="column">
          <ManagePaymentMethodButtonContainer>
            {cardLast4 && (
              <>
                You will be charged from:{" "}
                <b>
                  {cardBrand.toUpperCase()} *{cardLast4}
                </b>
              </>
            )}
            <ManagePaymentMethodButton onClick={handleManagePaymentMethod}>
              Manage payment method
            </ManagePaymentMethodButton>
          </ManagePaymentMethodButtonContainer>
        </Container>
      )}

      <Container bgColor="#fff" mt="30px" mb="24px">
        <Row>
          <Button className="row-button" type="coming-soon">
            Split the bill
          </Button>
          <Button className="row-button" type="coming-soon">
            Additional tips
          </Button>
        </Row>
      </Container>
      <Container mt="8px">
        <Button type="link" onClick={handleDispute}>
          Dispute
        </Button>
      </Container>
      <Container mt="8px">
        <BackToMessenger />
      </Container>
    </Root>
  );
};
