import styled from "styled-components";

export const Root = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Matter", sans-serif;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  border: none;
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  transition: background-color 0.2s;
  text-align: center;
`;

export const StyledRadioButton = styled.input.attrs({
  type: "radio",
})`
  width: 100%;
  appearance: none;
  position: static;
  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 8px;
    display: flex;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 8px;
  }
  &:checked {
    &::before {
      border: 2px solid #fff;
      background-color: #5b8f90;
    }
  }
`;
