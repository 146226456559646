import { APP_URL, SMS_PHONE_NUMBER, WHATSAPP_PHONE_NUMBER } from "./constants";
import isEmpty from "lodash/isEmpty";
import setWith from "lodash/setWith";
import clone from "lodash/clone";

export const compose =
  (...fns: any) =>
  (x: any) =>
    fns.reduceRight((res: any, fn: any) => fn(res), x);

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const smsLink = (phone: any, message?: string) => {
  let body = "";

  if (isIOS() && message) {
    body = `&body=${message}`;
  } else if (message) {
    body = `?body=${message}`;
  }
  return `sms:${phone}${body}`;
};

export const whatsappLink = (phone: any, message?: string) => {
  let body = "";

  if (message) {
    body = `?text=${message}`;
  }
  return `https://wa.me/${phone}${body}`;
};

export const encodeValueChars = (str) => {
  str = str.replace(/&/g, "and");
  return encodeURIComponent(str);
};

export const closeTab = () => {
  window.open("", "_self");
  window.close();
};

// close tab and open sms app
export const openSmsApp = () => {
  window.open(smsLink(SMS_PHONE_NUMBER), "_self");
  window.close();
};

export const openWhatsappApp = () => {
  window.open(whatsappLink(WHATSAPP_PHONE_NUMBER), "_self");
  window.close();
};

export const conditionalOpenApp = () => {
  const msrg = localStorage.getItem("msgr");

  if (msrg === "sms") {
    openSmsApp();
  } else if (msrg === "whatsapp") {
    openWhatsappApp();
  } else {
    closeTab();
  }
};

export const formatPrice = (price: number | string) => {
  const GBPFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });

  return GBPFormatter.format(+price / 100);
};

export const urlToApp = (url: string) => {
  return APP_URL + url;
};

// get current url
export const getCurrentUrl = () => {
  return window.location.href;
};

export const generateUrl = (url: string, params: any) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      urlParams.append(key, params[key]);
    }
  });
  return url + "?" + urlParams.toString();
};

export const objectsDifference = (obj1: any, obj2: any, compare: string[]) => {
  if (isEmpty(obj1) || isEmpty(obj2)) {
    return {};
  }

  const result = {};
  for (const key in getOnlyKeys(obj1, compare)) {
    if (obj1[key] !== obj2[key]) {
      result[key] = obj1[key];
    }
  }
  return result;
};

// remove keys from object
export const getOnlyKeys = (obj, keys) => {
  const result = {};
  for (const key in obj) {
    if (keys.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const setIn = (state, path, value) => {
  return setWith(clone(state), path, value, clone);
};

// check recursively if object has any value
export const isEmptyObject = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      return true;
    } else if (typeof obj[key] === "object") {
      return isEmptyObject(obj[key]);
    }
  }
  return false;
};

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {}

  document.body.removeChild(textArea);
};
export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {}
  );
};

export const setCookie = (
  name: string,
  value: string,
  expiresInDays: number = 365
) => {
  const date = new Date();
  date.setTime(date.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  const secureValue = encodeURIComponent(value);
  document.cookie =
    name + "=" + secureValue + ";" + expires + ";path=/;secure;sameSite=strict";
};

export const deleteCookie = (name: string) => {
  setCookie(name, "", -1);
};

export const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      const secureValue = cookie.substring(nameEQ.length, cookie.length);
      return decodeURIComponent(secureValue);
    }
  }
  return null;
};

export const tryParseJSON = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}
  return false;
};
