import { FC } from "react";
import { Root } from "./styles";

interface Props {
  src: string;
  width?: string;
  height?: string;
}

export const Icon: FC<Props> = ({ src, width, height }) => {
  return <Root src={src} width={width} height={height} />;
};

export const IconBack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10.5H6.6L10.065 7.035C10.3605 6.7395 10.5 6.387 10.5 6C10.5 5.262 9.89025 4.5 9 4.5C8.60175 4.5 8.2545 4.64475 7.965 4.935L1.9965 10.9035C1.7505 11.1495 1.5 11.4532 1.5 12C1.5 12.5468 1.70925 12.81 1.9845 13.0853L7.965 19.065C8.2545 19.3553 8.60175 19.5 9 19.5C9.891 19.5 10.5 18.738 10.5 18C10.5 17.613 10.3605 17.2605 10.065 16.965L6.6 13.5H21C21.828 13.5 22.5 12.828 22.5 12C22.5 11.172 21.828 10.5 21 10.5Z"
        fill="#212322"
      />
    </svg>
  );
};

export const IconBackBlack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10.5H6.6L10.065 7.035C10.3605 6.7395 10.5 6.387 10.5 6C10.5 5.262 9.89025 4.5 9 4.5C8.60175 4.5 8.2545 4.64475 7.965 4.935L1.9965 10.9035C1.7505 11.1495 1.5 11.4532 1.5 12C1.5 12.5468 1.70925 12.81 1.9845 13.0853L7.965 19.065C8.2545 19.3553 8.60175 19.5 9 19.5C9.891 19.5 10.5 18.738 10.5 18C10.5 17.613 10.3605 17.2605 10.065 16.965L6.6 13.5H21C21.828 13.5 22.5 12.828 22.5 12C22.5 11.172 21.828 10.5 21 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export const IconRadio = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="8" fill="white" />
    </svg>
  );
};

export const IconRadioChecked = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="8" fill="white" />
      <circle cx="12" cy="12" r="6" fill="#5B8F90" />
    </svg>
  );
};
