import React, { useEffect, useState } from "react";
import {
  useCreateClientPaymentMethodsMutation,
  useGetStripeClientSecretMutation,
  useUpdateClientMeMutation,
} from "../../common/store";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import * as yup from "yup";
import { get } from "lodash";
import { Container } from "../../components/container";
import { Input } from "../../components/input";
import { ErrorHint } from "../../components/error-hint";
import { Typography } from "../../components/typography";
import { RadioPayButton } from "../../components/radio-pay-button";
import { RadioCardButton } from "../../components/radio-card-button";
import { Button } from "../../components/button";
import { useSegment } from "../../components/segment";
import { useUrlMsgr } from "../../common/hooks/useUrlMsgr";

export const Form = ({ onSuccess }) => {
  const [getClientSecret] = useGetStripeClientSecretMutation();
  const [clientSecret, setClientSecret] = useState<string>();
  const [updateMe] = useUpdateClientMeMutation();
  const [createPaymentMethod] = useCreateClientPaymentMethodsMutation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const segment = useSegment();
  const messenger = useUrlMsgr();

  useEffect(() => {
    handleGetClientSecret();
  }, []);

  const handleGetClientSecret = () => {
    getClientSecret({})
      .unwrap()
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const {
    getFieldProps,
    errors,
    handleSubmit,
    setFieldValue,
    touched,
    isValid,
    values,
  } = useFormik({
    initialValues: {
      fullName: "",
      postalCode: "",
      walletPaymentMethod: null,
      walletType: "",
      cardPaymentMethod: null,
      selectedMethod: "",
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      fullName: yup.string().trim().required("Required field"),
      postalCode: yup.string().trim().required("Required field"),
      selectedMethod: yup.string().required("Required field"),
      walletPaymentMethod: yup.mixed().when("selectedMethod", {
        is: "wallet",
        then: yup.mixed().required("Required field"),
      }),
      cardPaymentMethod: yup.mixed().when("selectedMethod", {
        is: "card",
        then: yup.mixed().required("Required field"),
      }),
    }),
    onSubmit: ({
      fullName,
      postalCode,
      selectedMethod,
      cardPaymentMethod,
      walletPaymentMethod,
      walletType,
    }) => {
      setLoading(true);

      updateMe({ fullName, postalCode })
        .unwrap()
        .then(() => {
          segment.track("frontend_client_register", {
            fullName,
            messenger,
          });

          if (selectedMethod === "card") {
            const cardElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            stripe
              .confirmCardSetup(clientSecret, {
                payment_method: {
                  card: cardElement,
                  billing_details: get(cardPaymentMethod, "billingDetails", {
                    address: {
                      city: "United Kingdom",
                      country: "UK",
                      postal_code: postalCode,
                    },
                    name: fullName,
                  }),
                },
              })
              .then((res) => {
                const error = get(res, "error.message");
                if (error) {
                  alert(error);
                  return;
                }

                segment.track("frontend_client_register_added_wallet", {
                  type: "card",
                });

                cardElement.clear();
                cardExpiryElement.clear();
                cardCvcElement.clear();
                handleGetClientSecret();
                handleOnSuccess();
              })
              .catch(({ error }) => {
                handleGetClientSecret();
                alert(error.message);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            createPaymentMethod({
              paymentMethodId: walletPaymentMethod,
            })
              .unwrap()
              .then(() => {
                segment.track("frontend_client_register_added_wallet", {
                  type: walletType,
                });
                handleOnSuccess();
              })
              .catch((error) => {
                handleGetClientSecret();
                alert(error.message);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          handleGetClientSecret();
          alert(error.message);
        });
    },
  });

  const handleOnSuccess = () => {
    onSuccess && onSuccess();
  };

  const pmIsDisabled = !(values.fullName.trim() && values.postalCode.trim());

  useEffect(() => {
    setFieldValue("selectedMethod", "");
    setFieldValue("walletPaymentMethod", null);
    setFieldValue("cardPaymentMethod", null);
  }, [pmIsDisabled]);

  return (
    <>
      <Container direction="column" mt="24px">
        <Input label="Full name" {...getFieldProps("fullName")} />
        <ErrorHint error={touched.fullName && errors.fullName} />
      </Container>
      <Container direction="column" mt="8px">
        <Input label="Postal code" {...getFieldProps("postalCode")} />
        <ErrorHint error={touched.postalCode && errors.postalCode} />
      </Container>
      <Container direction="column" mt="24px">
        <Typography variant="x-large" color="#fff" textAlign="center">
          Select payment method
        </Typography>
      </Container>
      <Container direction="column" mt="24px">
        <RadioPayButton
          disabled={pmIsDisabled}
          checked={values.selectedMethod === "wallet"}
          onClick={() => setFieldValue("selectedMethod", "wallet")}
          onSuccess={(pm) => {
            setFieldValue("walletPaymentMethod", pm).then(() => {
              setTimeout(() => {
                handleSubmit();
              }, 100);
            });
          }}
          onSuccessWalletType={(walletType) =>
            setFieldValue("walletType", walletType)
          }
          onCancel={() => setFieldValue("selectedMethod", "")}
        />
        <RadioCardButton
          disabled={pmIsDisabled}
          clientSecret={clientSecret}
          checked={values.selectedMethod === "card"}
          onClick={() => setFieldValue("selectedMethod", "card")}
          onSuccess={(pm) => setFieldValue("cardPaymentMethod", pm)}
        />
      </Container>
      <Container direction="column" mt="24px">
        <Button
          disabled={!isValid}
          loading={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Confirm payment method
        </Button>
      </Container>
    </>
  );
};
