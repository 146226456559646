import { FC } from "react";
import { Root, StyledRadioButton } from "./styles";

interface Props {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  checked?: boolean;
}

export const RadioButton: FC<Props> = ({ id, name, onChange, checked }) => {
  return (
    <Root htmlFor={id}>
      <StyledRadioButton
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
      />
    </Root>
  );
};
