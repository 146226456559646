import { withBaseLayout } from "../../layouts";
import { WaiterBankDetailsContainer } from "../../features/WaiterBankDetails";
import { compose } from "../../common/utils";
import { withWaiterAuthorized } from "../../common/hocs/withWaiterAuthorized";
import { withUrlMsgr } from "../../common/hocs/withUrlMsgr";

export const WaiterBankDetails = compose(
  withUrlMsgr,
  withWaiterAuthorized,
  withBaseLayout
)(WaiterBankDetailsContainer);
