import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #212322;
  border-radius: 12px;
  padding: 11px 16px;
  margin-bottom: 8px;
  align-items: center;
`;

export const RadioButtonContainer = styled.div`
  width: 12px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DeleteIconContainer = styled.div`
  width: 20px;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`;

export const DeleteIconImg = styled.img`
  width: 20px;
`;

export const Title = styled.div`
  font-family: "Matter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.16px;
  color: #ffffff;
`;

export const CardTitle = styled.div`
  font-family: "Matter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #ffffff;
`;
