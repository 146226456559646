import styled from "styled-components";

interface ButtonProps {
  disabled?: boolean;
}

export const TranparentRoot = styled.button<ButtonProps>`
  font-family: "Garnett Semibold";
  padding: 8px 16px;
  border-radius: 16px;
  border: 1px solid white;
  background: transparent;
  color: white;
  font-size: 12px;
  box-sizing: border-box;
  transition: background-color 0.2s;
  &:active {
    background-color: #ffffff22;
  }
  ${(p) => p.disabled && `background-color: #ffffff22;`}
  > img {
    margin-right: 10px;
  }
`;

export const LinkRoot = styled.button<ButtonProps>`
  font-family: "Garnett Semibold";
  padding: 16px;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #212322;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
`;

export const ComingSoonRoot = styled.button<ButtonProps>`
  position: relative;
  font-family: "Matter", sans-serif;
  font-weight: 600;
  padding: 16px;
  border-radius: 8px;
  background: #f2f2f2;
  pointer-events: none;
  border: none;
  color: #21232266;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  &::before {
    top: -12px;
    right: 10px;
    position: absolute;
    content: "Coming soon";
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    line-height: 10px;
    font-size: 10px;
    background: #fddeae;
    border-radius: 12px;
    font-family: "Garnett Semibold";
    color: #212322;
  }
`;

export const SecondaryRoot = styled.button<ButtonProps>`
  font-family: "Garnett Semibold";
  padding: 16px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid #212322;
  color: #212322;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  transition: opacity 0.2s;
  &:active {
    opacity: 0.8;
  }
`;

export const PrimaryRoot = styled.button<ButtonProps>`
  font-family: "Garnett Semibold";
  padding: 16px;
  border-radius: 8px;
  background: #212322;
  border: none;
  color: white;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  transition: background-color 0.2s;
  cursor: pointer;
  min-height: 52px;
  &:active {
    background-color: #21232288;
  }
  ${(p) => p.disabled && `background-color: #21232288;`}

  &.-loading {
    cursor: progress;
    padding: 0;
  }
  & .loader {
    width: 47px;
  }
`;
