const API_URL = process.env.REACT_APP_API_URL;
//FIXME: remove after env variables will be set
export const ENDPOINT_ROOT = API_URL ? API_URL : "https://api.alacart.me";

export const APP_URL = process.env.REACT_APP_URL || "https://pay.alacart.me";

export const AUTH_TOKEN = "token";

export const ROUTE_SIGN_IN = "/sign-in";
export const ROUTE_CLIENT_MANAGE_CARDS = "/client-manage-cards";
export const ROUTE_CLIENT_ADD_CARD = "/client-add-new-card";
export const ROUTE_CLIENT_SIGN_UP = "/client-sign-up";
export const ROUTE_CLIENT_BILL = "/client-bill";

export const SMS_PHONE_NUMBER =
  process.env.REACT_APP_SMS_PHONE_NUMBER || "+447400036133";

export const WHATSAPP_PHONE_NUMBER =
  process.env.REACT_APP_WHATSAPP_PHONE_NUMBER || "+447400036133";
export const STATE_NEED_CHANGE_CARD = "needChangeCard";
export const STATE_AUTO_CHARGE_ERROR = "autoChargeError";
export const STATE_WITH_BACK = "withBack";

export const MESSENGERS = {
  sms: "SMS",
  whatsapp: "WhatsApp",
};

export const STRIPE_KEY =
  process.env.REACT_APP_STRIPE_KEY ||
  "pk_test_51M2ZbCJ9WVuNLJDGZOYTpU080iPBzeFJRuxtx92gcQY0fXHSjlDAUHqYQ3oYpFGedE7poQ9IBAdB2sHyqZg7wtDK00kkoBa2ML";

export const CLIENT_ACCEPTED_TERMS_KEY = "client_accepted_terms";
export const CLIENT_VIA_MESSENGER_KEY = "client_via_messenger";

export const GTM_ID = process.env.REACT_APP_GTM_ID || "GTM-TZPZB9D";
export const SEGMENT_KEY =
  process.env.REACT_APP_SEGMENT_KEY || "e6IjRp1ZNBn02puOSvfmFzJqcTBKb3wX";
