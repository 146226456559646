import styled from "styled-components";

interface RootProps {
  src: HTMLImageElement | string;
  width?: number | string;
  height?: number | string;
}

export const Root = styled.img<RootProps>`
  width: ${(p) => p.width || "auto"};
  height: ${(p) => p.height || "auto"};
`;
