import { useEffect } from "react";
import { useSegment } from "../../components/segment";

export const useReportPageLoadMetrics = () => {
  const segment = useSegment();

  useEffect(() => {
    try {
      const perfObserver = new PerformanceObserver((observedEntries) => {
        const entry: PerformanceEntry =
          observedEntries.getEntriesByType("navigation")[0];
        const duration = Math.round(entry.duration);

        if (duration > 0) {
          segment.track("frontend_page_load", {
            pathname: window.location.pathname,
            duration,
          });
        }
      });

      perfObserver.observe({
        type: "navigation",
      });
    } catch (e) {}
  }, []);
};
