import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { CheckboxButton } from "../../components/checkbox-button";
import { Container } from "../../components/container";
import { Root, Logo } from "./styles";
import { Typography } from "../../components/typography";
import { encodeValueChars, smsLink, whatsappLink } from "../../common/utils";
import { useFetchOneRestaurantMutation } from "../../common/store";
import { get } from "lodash";
import { NotificationBox } from "../../components/notification-box";
import {
  SMS_PHONE_NUMBER,
  WHATSAPP_PHONE_NUMBER,
} from "../../common/constants";
import { useSearchParams } from "../../common/hooks/useSearchParams";
import { useSegment } from "../../components/segment";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const WaiterSignUpContainer = () => {
  useReportPageLoadMetrics();
  const { restaurant: id } = useSearchParams();
  const [accepted, setAccepted] = useState(false);
  const [errors, setErrors] = useState();
  const [fetchOneRestaurant, { data: restaurant }] =
    useFetchOneRestaurantMutation();
  const segment = useSegment();

  useEffect(() => {
    fetchOneRestaurant({ id })
      .unwrap()
      .catch((err) => {
        setErrors(err);
      });
  }, [id]);

  const getWelcomeMessage = () => {
    const sid = get(restaurant, "sid", "");
    const name = get(restaurant, "name", "");
    return encodeValueChars(
      `Hi Alacart, please register me at ${name} RestaurantID:${sid}. Thank you!`
    );
  };

  const handleClickSms = () => {
    const link = smsLink(SMS_PHONE_NUMBER, getWelcomeMessage());
    segment.track("frontend_waiter_button_clicked", {
      name: "continue_via_sms",
      restaurantId: id,
    });
    window.open(link, "_blank");
  };

  const handleClickWhatsapp = () => {
    const link = whatsappLink(WHATSAPP_PHONE_NUMBER, getWelcomeMessage());
    segment.track("frontend_waiter_button_clicked", {
      name: "continue_via_whatsapp",
      restaurantId: id,
    });
    window.open(link, "_blank");
  };

  return (
    <Root>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="20vh"
      >
        <Typography
          variant="xx-large"
          color="#ffffff"
          fontFamily="Matter, sans-serif"
          fontWeight="bold"
        >
          Welcome to
        </Typography>
        <Logo />
      </Container>
      {restaurant && (
        <>
          <Container
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt="20vh"
          >
            <CheckboxButton
              id={"privacy-terms-accept"}
              onChange={(event) => setAccepted(event.target.checked)}
              isChecked={accepted}
            >
              I agree with the <Link to="/privacy-policy">Privacy Policy</Link>{" "}
              and the <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </CheckboxButton>
          </Container>
          <Container
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt="2vh"
          >
            <Button disabled={!accepted} onClick={handleClickSms}>
              Continue via SMS
            </Button>
          </Container>
          <Container
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt="2vh"
          >
            <Button disabled={!accepted} onClick={handleClickWhatsapp}>
              Continue via WhatsApp
            </Button>
          </Container>
        </>
      )}

      {errors && (
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="8vh"
        >
          <NotificationBox
            title="Error"
            content={get(errors, "data.message")}
          />
        </Container>
      )}
    </Root>
  );
};
