import React, { FC } from "react";
import { Root } from "./styles";

export interface ContainerProps {
  children?: React.ReactNode;
  direction?: "row" | "column";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around";
  alignItems?: "flex-start" | "flex-end" | "center";
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  p?: string;
  textAlign?: string;
  width?: string;
  maxWidth?: string;
  bgColor?: string;
  borderRadius?: string;
  minHeight?: string;
}

export const Container: FC<ContainerProps> = ({
  children,
  direction,
  justifyContent,
  alignItems,
  mt,
  mb,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  p,
  textAlign,
  width,
  maxWidth,
  bgColor,
  borderRadius,
  minHeight,
}) => {
  return (
    <Root
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      p={p}
      textAlign={textAlign}
      width={width}
      maxWidth={maxWidth}
      bgColor={bgColor}
      borderRadius={borderRadius}
      minHeight={minHeight}
    >
      {children}
    </Root>
  );
};
