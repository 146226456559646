import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WifiIcon from "../../assets/images/wifi-icon.png";
import { Button } from "../../components/button";
import { CheckboxButton } from "../../components/checkbox-button";
import { Icon } from "../../components/icon";
import { Container } from "../../components/container";
import { Root, Logo } from "./styles";
import { Typography } from "../../components/typography";
import {
  encodeValueChars,
  getCookie,
  setCookie,
  smsLink,
  whatsappLink,
} from "../../common/utils";
import isEmpty from "lodash/isEmpty";
import { NotificationBox } from "../../components/notification-box";
import { get } from "lodash";
import {
  CLIENT_ACCEPTED_TERMS_KEY,
  CLIENT_VIA_MESSENGER_KEY,
  SMS_PHONE_NUMBER,
  WHATSAPP_PHONE_NUMBER,
} from "../../common/constants";
import { Loading } from "../../components/loading";
import { useSearchParams } from "../../common/hooks/useSearchParams";
import { WifiModal } from "../../components/modals/wifi-modal";
import { useFetchCardInfoQuery } from "../../common/store";
import { useSegment } from "../../components/segment";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const ClientSignUpContainer = () => {
  const [accepted, setAccepted] = useState(false);
  const { card: id } = useSearchParams();
  const [pollingInterval, setPollingInterval] = useState(3000);
  const [showWifiModal, setShowWifiModal] = useState(false);
  const segment = useSegment();
  useReportPageLoadMetrics();

  const { data: order, isLoading } = useFetchCardInfoQuery(
    { id },
    {
      pollingInterval,
      skip: isEmpty(id),
    }
  );

  const restaurantId = get(order, "restaurant.id", "");

  useEffect(() => {
    if (!isEmpty(order)) {
      setPollingInterval(0);
    }
  }, [order]);

  useEffect(() => {
    const termsAccepted = getCookie(CLIENT_ACCEPTED_TERMS_KEY);
    const via = getCookie(CLIENT_VIA_MESSENGER_KEY);

    if (termsAccepted && !isEmpty(order)) {
      setAccepted(true);
      if (via === "whatsapp") {
        handleClickWhatsapp();
      } else {
        handleClickSms();
      }
    }
  }, [order]);

  const getWelcomeMessage = () => {
    const name = get(order, "restaurant.name", "");

    return encodeValueChars(
      `Hi Alacart, please open my tab at ${name} CardID:${id}. Thank you!`
    );
  };

  const handleAcceptedChange = (event) => {
    setAccepted(event.target.checked);
  };

  const handleClickSms = () => {
    setCookie(CLIENT_ACCEPTED_TERMS_KEY, "1");
    setCookie(CLIENT_VIA_MESSENGER_KEY, "sms");
    segment.track("frontend_client_button_clicked", {
      name: "continue_via_sms",
      cardId: id,
      restaurantId,
    });
    const link = smsLink(SMS_PHONE_NUMBER, getWelcomeMessage());

    window.open(link, "_self");
  };

  const handleClickWhatsapp = () => {
    setCookie(CLIENT_ACCEPTED_TERMS_KEY, "1");
    setCookie(CLIENT_VIA_MESSENGER_KEY, "whatsapp");
    segment.track("frontend_client_button_clicked", {
      name: "continue_via_whatsapp",
      cardId: id,
      restaurantId,
    });
    const link = whatsappLink(WHATSAPP_PHONE_NUMBER, getWelcomeMessage());

    window.open(link, "_self");
  };

  const handleClickConnectToWifi = () => {
    segment.track("frontend_client_button_clicked", {
      name: "connect_to_wifi",
      cardId: id,
      restaurantId,
    });
    setShowWifiModal(true);
  };

  const handleCloseWifiModal = () => {
    setShowWifiModal(false);
  };

  const wifiSsid = get(order, "restaurant.wifiSsid");
  const wifiPassword = get(order, "restaurant.wifiPassword");

  return (
    <Root>
      {wifiSsid && (
        <Container
          flex-direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button type="transparent" onClick={handleClickConnectToWifi}>
            <Icon width="15px" src={WifiIcon} /> Connect to WiFi
          </Button>
        </Container>
      )}

      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="84px"
      >
        <Typography
          variant="xx-large"
          color="#ffffff"
          fontFamily="Matter, sans-serif"
          fontWeight="bold"
        >
          Welcome to
        </Typography>
        <Logo />
      </Container>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="84px"
      >
        <Typography
          variant="xx-large"
          color="#ffffff"
          fontFamily="Matter, sans-serif"
          fontWeight="bold"
          textAlign="center"
        >
          Never wait for the bill again.
          <br />
          Start a tab. Eat. Leave.
        </Typography>
      </Container>
      {isLoading ? (
        <Loading pt="100px" />
      ) : (
        <>
          {!isEmpty(order) ? (
            <>
              <Container
                direction="column"
                justifyContent="center"
                alignItems="center"
                mt="20vh"
              >
                <CheckboxButton
                  id={"privacy-terms-accept"}
                  onChange={handleAcceptedChange}
                  isChecked={accepted}
                >
                  I agree with the{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link> and the{" "}
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </CheckboxButton>
              </Container>
              <Container
                direction="column"
                justifyContent="center"
                alignItems="center"
                mt="2vh"
              >
                <Button disabled={!accepted} onClick={handleClickSms}>
                  Continue via SMS
                </Button>
              </Container>
              <Container
                direction="column"
                justifyContent="center"
                alignItems="center"
                mt="2vh"
              >
                <Button disabled={!accepted} onClick={handleClickWhatsapp}>
                  Continue via WhatsApp
                </Button>
              </Container>
            </>
          ) : (
            <Container
              direction="column"
              justifyContent="center"
              alignItems="center"
              mt="8vh"
            >
              <NotificationBox
                title="This tab was not opened yet"
                content="Please wait a minute and your waiter will setup your table."
              />
            </Container>
          )}
        </>
      )}
      <WifiModal
        isOpen={showWifiModal}
        closeModal={handleCloseWifiModal}
        wifiSsid={wifiSsid}
        wifiPassword={wifiPassword}
      />
    </Root>
  );
};
