import { Container } from "../../components/container";
import { Root, Logo, CodeWrapper, CodeTitle, Code } from "./styles";
import { Typography } from "../../components/typography";
import { useParams } from "react-router-dom";
import { BackToMessenger } from "../../components/back-to-messenger";
import React from "react";
import { useGetClientOrderQuery } from "../../common/store";
import get from "lodash/get";
import { Loading } from "../../components/loading";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const ClientCodeContainer = () => {
  const { orderSid: id } = useParams();
  useReportPageLoadMetrics();

  const { data: order, isLoading } = useGetClientOrderQuery({ id });

  const code = get(order, "code", " ");

  return (
    <Root>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="57px"
      >
        <Logo />
      </Container>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="24px"
      >
        <Typography
          variant="x-large"
          color="#ffffff"
          fontFamily="Matter, sans-serif"
          textAlign="center"
        >
          You’re all set. Your tab is open!
          <br />
          Your table code below.
          <br />
          Please provide it to the waiter.
        </Typography>
      </Container>
      {isLoading ? (
        <CodeWrapper>
          <Loading></Loading>
        </CodeWrapper>
      ) : (
        <CodeWrapper>
          <CodeTitle>Your table code</CodeTitle>
          <Code>{code}</Code>
        </CodeWrapper>
      )}

      <Container maxWidth="343px" ml="auto" mr="auto">
        <BackToMessenger type="primary" />
      </Container>
    </Root>
  );
};
