import { BackButton, Content, PageTitle, SubTitle } from "./styles";
import { Container } from "../../components/container";
import { IconBack } from "../../components/icon";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const TermsAndConditionsContainer = () => {
  useReportPageLoadMetrics();
  return (
    <>
      <Container>
        <BackButton
          onClick={() => {
            window.history.back();
          }}
        >
          <IconBack />
        </BackButton>
      </Container>
      <Container mt="32px" direction="column">
        <PageTitle>Terms & Conditions</PageTitle>
        <i>Last Updated: 9 february, 2023 </i>
        <SubTitle>Welcome to Alacart!</SubTitle>
        <Content>
          <p>
            Alacart International Limited, a limited liability company
            incorporated and registered in England and Wales (registration
            number: 14302517), whose registered office address is at 3rd Floor,
            1 Ashley Road, Altrincham, Cheshire, WA14 2DT, United Kingdom{" "}
            <b>(“Alacart,” “we,” “us,” “our”)</b> provides its Service (as
            defined below) subject to the following terms of service (as amended
            from time to time, the “Terms of Service”). We reserve the right, at
            our sole discretion, to change or modify any part of these Terms of
            Service at any time. If we do this, we will notify you of the
            changes next time you request to open a tab with us or use the
            Chatbot (as defined below). Your continued use of the Service after
            the date any such changes become effective constitutes your
            acceptance of the new Terms of Service.
          </p>
          <p>
            <b>
              By accessing and using the Service, you agree to be bound by these
              Terms of Service. You should pay particular attention to the
              section called <u>"Disclaimers of Liability"</u>, as this excludes
              or limits our legal liability in connection with your use of the
              Service.{" "}
              <u>
                If you do not agree to these Terms of Service, please do not use
                our Service.
              </u>
            </b>
          </p>
          <p>
            If you breach any of these Terms of Service, we have the right to
            terminate your access to and use of the Service (and block you from
            future access and use of the Service) immediately without notice.
          </p>
          <ol>
            <li>
              <b>Access to and Use of the Service</b>
              <ul>
                <li>
                  <b>Service Description:</b> The Service is designed to allow
                  you to make payments for menu items <b>(“Menu Items”)</b>{" "}
                  ordered at restaurants who have chosen to join our platform{" "}
                  <b>(“Restaurants”)</b> via our platform, without waiving down
                  a server or waiting for a card machine (collectively, such
                  services, including our website available at www.alacart.me
                  and any new features and applications, being the “Service”).
                </li>
                <li>
                  <b>Eligibility.</b> You must be aged 18 or older to use our
                  Service. By accessing and/or using the Service, you confirm
                  that you are aged 18 or older. You will also need access to
                  the WhatsApp application on your mobile device in order to use
                  the Service. WhatsApp’s terms of use apply to your use of
                  WhatsApp and you must comply with WhatsApp’s terms of use at
                  all times.
                </li>
                <li>
                  <b>How to Access and use the Service.</b> To access and use
                  our Service, you simply need to connect your smartphone to our
                  Service by scanning a QR code or an NFC tag, which will be
                  provided to you by a member of the serving staff (a “Waiter”)
                  at a Restaurant. The QR Code or NFC tag will redirect you to
                  the Alacart chatbot via WhatsApp (the “Chatbot”) and you will
                  see a pre-composed message with a user ID decoded from the
                  card containing the QR code or NFC tag you scanned (“Alacart
                  Card”). Once the Chatbot has opened a conversation with you
                  via WhatsApp, you will be asked to confirm your desired
                  payment method (“Payment Method”). If you are using the
                  Service for the first time or adding a new Payment Method, you
                  will be asked to choose it from the available options, which
                  currently are Apply Pay, Google Pay, and Stripe. Any different
                  payment options we have available will be communicated to you
                  at the time of opening a new tab with a Restaurant. The
                  Chatbot will then direct you to click on a link which will
                  take you to our website (available at www.alacart.me) to enter
                  the relevant details for your preferred Payment Method. Once
                  your Payment Method has been successfully added, you will
                  receive a message in the WhatsApp chatbot to confirm this. By
                  opening a tab and confirming your Payment Method, you
                  authorise Alacart to deduct all amounts owed for your Menu
                  Items (including tips or service fees charged by the
                  Restaurant), plus our Service Fees (as defined below) from
                  that Payment Method, and you will be asked to confirm that you
                  agree to this. If you make payment via Apple Pay, Google Pay,
                  Stripe or another third party payment provider, such third
                  party payment provider will take your payment and their terms
                  of use will apply. The relevant third party payment provider’s
                  terms of use will be made available to you at the time you
                  make your payment. Our Service will store your selected
                  Payment Methods, however you can always opt to delete them via
                  the Chatbot or our website (www.alacart.me).
                </li>
                <li>
                  <b>Our Contract with You.</b> A request by you to set up a tab
                  constitutes an offer by you to create a contract to use the
                  Service, subject to and in accordance with these terms and
                  conditions <b>(“Offer”)</b>. If we accept your Offer (which we
                  reserve the right to refuse, and which we will only do after
                  we have set up and verified your Payment Method), the Chatbot
                  will notify you that your tab at the Restaurant has been
                  opened. At that point, a contract for your use of the Service
                  will be formed between you and us subject to and in accordance
                  with these Terms of Service. You will then be able to get up
                  and walk out of the Restaurant whenever you like without
                  settling the bill with the Restaurant directly, and payment
                  for your Menu Items (plus any tips or service fees charged by
                  the Restaurant, and our Service Fees) will be made via the
                  Service. If you prefer, you can choose to settle your bill
                  directly with the Restaurant. Our contract with you relates
                  solely to the Service.
                </li>
                <li>
                  <b>Your Contract with Restaurants.</b> We only act as a
                  commercial agent for the Restaurants to collect payment for
                  your Menu Items. The contract for the supply of any Menu Items
                  you have ordered from a Restaurant is between you and the
                  relevant Restaurant and each Restaurant (including its
                  Waiters) is solely responsible for any Menu Items, or other
                  goods, services, advice, information or materials given to
                  you. We simply provide a platform that enables you to pay for
                  your Menu Items. We do not provide any of the food, beverages
                  or other Menu Items that you can purchase from a Restaurant
                  and we are not responsible or liable for the preparation or
                  supply of any Menu Items. Without limiting the previous
                  sentence, we have no liability for any Menu Items that contain
                  allergens or have not been safely prepared.
                </li>
                <li>
                  <b>Information You Give Us</b>: You are required to give
                  certain information to Alacart in order to access and use the
                  Service (including but not limited to your name, Payment
                  Method details, and mobile phone number). You agree to provide
                  and maintain true, accurate and current information about
                  yourself as prompted by the Service. By Using the Service, you
                  warrant that any information you provide, whether to us or a
                  Restaurant, Waiter or third party payment provider, is true
                  and accurate, is not misleading, and is offered in good faith.
                  If you choose not to provide any information requested via the
                  Service, you acknowledge and agree that you may not be able to
                  use or benefit from certain features of the Service.
                  Information you submit via the Service, and certain other
                  information about you, are governed by our{" "}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </li>
                <li>
                  <b>Member Account, Password and Security</b>: You are
                  responsible for maintaining the confidentiality of your
                  password and account for WhatsApp and are fully responsible
                  for any and all activities that occur on the Service via your
                  account with us, or any WhatsApp account for which you are
                  responsible. You agree to immediately notify Alacart of any
                  unauthorised use of your WhatsApp or Alacart account or any
                  other breach of security. Alacart will not be liable for any
                  loss or damage arising from your failure to comply with this
                  clause.
                </li>
                <li>
                  <b>Your Role</b>. It is your sole responsibility to include
                  accurate information about yourself in the Service and to tell
                  the Restaurant and Waiters about any allergies or special
                  needs that are or may be relevant to the services and Menu
                  Items the Restaurants provide. You are responsible for making
                  all arrangements necessary to access and use the Service
                  (including downloading and registering with WhatsApp) and
                  should ensure you have sufficient anti-virus software.
                  Cancellation Rights. You acknowledge and agree that you do not
                  have a right to cancel the contract for the provision of our
                  Service because you have requested the supply of the services
                  before expiry of the cancellation period under consumer laws,
                  and the Service will be fully performed by the time we take
                  payment for our Service Fees (as defined below).
                </li>
                <li>
                  <b>Account suspension / closing your account</b>: You can
                  close your Alacart account at any time with or without notice
                  to us, by following the instructions to delete your account
                  and Payment Method via the WhatsApp chatbot.
                </li>
                <li>
                  <b>Modifications to Service</b>: Access to and use of the
                  Service is permitted on a temporary basis. Alacart reserves
                  the right to modify or discontinue, temporarily or
                  permanently, the Service (or any part of it) with or without
                  notice. You agree that Alacart will not be liable to you or to
                  any third party for any modification, suspension or
                  discontinuance of the Service.
                </li>
                <li>
                  <b>Beta Features</b>. Alacart may, from time to time, create
                  experimental enhancements, upgrades, updates, improvements,
                  modifications, extensions and other experimental changes to
                  the Service and may make these features available to you for
                  early access (“Beta Features”). Alacart may communicate an
                  offer for use of these services to you for purchase or on a
                  free of charge trial basis. You may elect to trial these Beta
                  Features by communicating such election to Alacart via the
                  Chatbot, and by doing so, you hereby authorise Alacart to
                  implement such Beta Features and you agree to pay, on demand,
                  any applicable fee for use of the Beta Features. You
                  understand that Alacart is not responsible for the stability
                  or functionality of Beta Features until such Beta Features
                  have been made into platform updates. You or we may terminate
                  your access to Beta Features at any time. You understand and
                  agree that to the fullest extent permitted by law: (1) Beta
                  Features are provided on an “as-is” and “as available” basis,
                  without any representation, warranty or indemnity and at your
                  sole risk; (2) we disclaim all liability in respect of the
                  same; and (3) your exclusive remedy if it is not satisfied
                  with any Beta Features or you have suffered loss or damage is
                  to cease use of such Beta Features.
                </li>
              </ul>
            </li>
            <li>
              <b>How Payment Works</b>
              <ul>
                <li>
                  <b>Paying your Restaurant Bill</b>. If the Restaurant has
                  connected its point of sale system (“POS”) to our Service,
                  then you will be able to check your live bill with the
                  Restaurant via the Chatbot at any time. If the Restaurant has
                  not connected its POS to our Service, this functionality will
                  not be available. At the end of your meal, when you are ready
                  to proceed to payment, you can either: (a) click ‘Request
                  Final Bill’, and (once the Restaurant has confirmed that your
                  final bill has been uploaded to the Service) choose ‘Pay Now’
                  via the Chatbot; or (b) simply walk out, and we will deduct
                  all amounts owed for Menu Items (including any tips or service
                  fees charged by the Restaurant), together with our Service Fee
                  (as defined below) from your chosen Payment Method. If at the
                  end of your meal you decide not to make payment via the
                  Service, you can ask your Waiter to close the tab and to bring
                  you a bill to pay the Restaurant directly. Your Waiter will
                  then notify us that you have paid the bill. If you don’t
                  either settle your bill via the Service or in person with the
                  Waiter within 15 minutes of either you clicking Request Final
                  Bill or your Waiter notifying us that you have left the
                  Restaurant, payment for your Menu Items (including any tips or
                  service fees charged by the Restaurant), together with our
                  Service Fee (as defined below) will be automatically be taken
                  via your selected Payment Method. Payment is made directly to
                  Alacart acting as commercial agent on behalf of the
                  Restaurants only. We are authorised by the Restaurants to
                  accept payment on their behalf and payment of any amounts you
                  owe for your Menu Items to us will fulfil your obligation to
                  pay the Restaurant for such Menu Items. The prices for Menu
                  Items are set by the Restaurants and will be communicated to
                  you by the relevant Restaurants at the time you order. It is
                  your responsibility to ensure you are aware of the amounts
                  Restaurants will charge you for Menu Items (including but not
                  limited to any tips or service fees the Restaurant may add to
                  your bill) prior to ordering them.
                </li>
                <li>
                  <b>Our Fees</b>. We reserve the right to charge you a fee
                  <b>(“Service Fee”)</b>, which may be subject to change, for
                  your access to and use of our Service. You will be notified of
                  any applicable Service Fees (or the manner in which they will
                  be calculated, e.g. on a percentage basis) and taxes prior to
                  setting up your tab through the Chatbot.
                </li>
                <li>
                  <b>Authorisation Hold</b>. Once your Payment Method has been
                  set up, we may verify your Payment Method by issuing a
                  temporary authorisation hold from your bank. This will usually
                  be either an estimate of your total bill, or a nominal amount
                  of e.g. £0.01. Once you have paid your bill and all associated
                  Service Fees and Credit Card Fees, we will cancel the
                  authorisation hold automatically. Your bank may take some time
                  to cancel the hold, however (in some cases up to one to two
                  weeks), so you may temporarily see two charges on your account
                  balance. We are not responsible for the time taken for your
                  bank to cancel the hold amount, and we can’t make them do this
                  any faster.
                </li>
                <li>
                  <b>Invoices</b>. After you have settled your tab via the
                  Service, you will receive an invoice, either in electronic
                  form via the Service or as an image of the physical bill
                  issued by the Restaurant.
                </li>
                <li>
                  <b>Issues and Disputes</b>. If you have any disputes or
                  complaints in relation to payment, please contact Alacart’s
                  support team via the WhatsApp chatbot. If you have any issues
                  or concerns with any Menu Items (including amounts charged to
                  you for Menu Items), advice, information, instructions,
                  materials or content provided to you by a Restaurant or
                  Waiter, you should raise these directly with the Restaurant or
                  your Waiter. If you request a refund or dispute any payment,
                  we and the Restaurant may take into account relevant factors
                  in considering your request, such as the details of your bill,
                  your account history (including any previous refund requests
                  you have made), and information from the Waiter and/or
                  Restaurants. If the Restaurant agrees that you are entitled to
                  a refund, we will provide a refund or account credit in
                  respect of the affected Menu Item(s). As noted above, the
                  contract for the supply of Menu Items is between you and the
                  Restaurant, and we are not able to issue refunds in respect of
                  amounts owed by you to a Restaurant for Menu Items without the
                  Restaurant’s consent.
                </li>
              </ul>
            </li>
            <li>
              <b>Intellectual Property Rights</b>
              <ul>
                <li>
                  <b> Service Content, Software and Trademarks</b>. You
                  acknowledge and agree that the Service and its content and
                  features (“Service Content”) are protected by copyright,
                  patent, trademark, trade secret or other proprietary rights
                  and laws. Alacart grants to you a limited, revocable,
                  non-exclusive, non-transferable and non-sublicensable licence
                  to access and use the Service and Service Content solely to
                  the extent required to enable you to set up tabs and pay bills
                  for Menu Items at Restaurants via the Service. You may only
                  use the Service for your own private use, and not for any
                  commercial, business or resale purposes. Except as expressly
                  authorised by Alacart, you agree not to modify, copy, reverse
                  engineer, reverse assemble or otherwise attempt to discover
                  any source code in any Service Content, or to frame, scrape,
                  rent, lease, loan, sell, assign, sublicense, distribute or
                  create derivative works based on, or otherwise transfer any
                  right or interest in any Service Content, in whole or in part,
                  except to the extent such actions cannot be restricted by
                  applicable law. In connection with your use of the Service you
                  will not engage in or use any data mining, robots, scraping or
                  similar data gathering or extraction methods. If you are
                  blocked by Alacart from accessing the Service or any Service
                  Content (including by blocking your IP address, email address,
                  name or credit card details), you agree not to implement any
                  measures to circumvent such blocking (e.g., by masking your IP
                  address or using a proxy IP address). Any use of the Service
                  or Service Content other than as specifically authorised
                  herein is strictly prohibited. The technology and software
                  underlying the Service or distributed in connection therewith
                  are the property of Alacart, our affiliates and our licensors.
                  Any rights not expressly granted herein are reserved by
                  Alacart.
                </li>
                <li>
                  <b>Trade Marks</b>. The Alacart name and logos are trademarks
                  and service marks of Alacart (collectively the “Alacart
                  Trademarks”). Other product and service names and logos used
                  and displayed via the Service and/or Service Content may be
                  trademarks or service marks of their respective owners who may
                  or may not endorse or be affiliated with or connected to
                  Alacart. Nothing in these Terms of Service or the Service
                  should be construed as granting, by implication, estoppel, or
                  otherwise, any license or right to use any of Alacart
                  Trademarks or any other trade marks, service marks or logos
                  displayed on the Service or Service Content, without our prior
                  written permission in each instance. All goodwill generated
                  from the use of Alacart Trademarks will inure to our exclusive
                  benefit.
                </li>
                <li>
                  <b>Feedback</b>. If you provide Alacart with suggestions or
                  comments for enhancements or improvements, new features or
                  functionality or other feedback about Alacart or the Service,
                  Alacart will have: (a) full discretion to determine whether or
                  not to proceed with the development of any requested
                  enhancements, new features or functionality; and (b) an
                  unencumbered right, without any obligation to compensate or
                  reimburse you, to use, incorporate and otherwise fully
                  exercise and exploit any such suggestions or comments in
                  connection with our products and services.
                </li>
                <li>
                  <b>Customer Data</b>: You grant to Alacart, its affiliates and
                  service providers, a non-exclusive, non-transferable,
                  sub-licensable (through multiple tiers), fully paid-up,
                  worldwide right and licence to use all data, information,
                  documentation and all other materials provided by, or on
                  behalf of, you to Alacart or submitted by or on behalf of you
                  to the Service (“Customer Data”): (a) to provide the Service;
                  (b) on a perpetual, irrevocable basis, to internally develop
                  and improve our products and services; and (c) on a perpetual,
                  irrevocable basis, to create anonymised or aggregated Customer
                  Data and other data collected by us in connection with the
                  Services (“System Data”). You acknowledge and agree that
                  Alacart may share Customer Data with Restaurants, Waiters and
                  our service providers. You will own all Customer Data, and
                  Alacart has no rights to it other than those specifically set
                  out in these Terms of Use. All personal data forming part of
                  Customer Data will be processed by us in accordance with our
                  Privacy Policy (available at link).
                </li>
                <li>
                  <b>Usage Data</b>. You acknowledge and agree that we may
                  collect and retain data relating to your use of the Service
                  and your activities and purchase history at Restaurants
                  (“Usage Data”). Alacart shall own all such Usage Data, except
                  to the extent that it contains any personal data. You
                  acknowledge and agree that Alacart may share Customer Data
                  with Restaurants, Waiters and our service providers. Any
                  personal data contained in or forming part of the Usage Data
                  will be processed by us in accordance with our Privacy Policy
                  (available at link).
                </li>
                <li>
                  <b>Alacart Data</b>. As between Alacart and you, Alacart
                  exclusively owns all data, information, documentation and all
                  other materials contained in and / or made available through
                  the Service and / or provided by, or on behalf of, Alacart to
                  you, including but not limited to any third party data and the
                  Usage Data and System Data (together, the “Alacart Data”), but
                  excluding the Customer Data.
                </li>
                <li>
                  <b>Confidentiality</b>. Each party agrees that to keep all
                  confidential information of the other party (including the
                  Service, Service Content and Alacart Data, which are the
                  confidential information of Alacart) confidential, to use it
                  solely in accordance with the provisions of these Terms and
                  Conditions, and not to disclose it to any third party without
                  the other party’s prior written consent, except as otherwise
                  permitted hereunder (including without limitation as permitted
                  under clause 3(d) and clause 3(e) above). However: (a) Alcart
                  may disclose your confidential information to Restaurants and
                  its and their employees, officers, directors, attorneys,
                  auditors, financial advisors and other representatives who
                  have a need to know such confidential information; and (b)
                  either party may disclose confidential information of the
                  other party as required by law (in which case the receiving
                  party will provide the disclosing party with prior written
                  notification thereof, will provide the disclosing party with
                  the opportunity to contest such disclosure, to the extent
                  permitted by applicable law).
                </li>
              </ul>
            </li>
            <li>
              <b>Our Liability to You.</b>
              <ul>
                <li>
                  <b>Liability which we do not exclude or limit</b>. Nothing in
                  these Terms of Service will limit or exclude our liability to
                  you for death or personal injury caused by our negligence,
                  fraudulent misrepresentation, or any other liability which
                  cannot be limited or excluded by law. If you are a consumer,
                  you have legal rights in relation to services that are not
                  performed with reasonable care and skill or are otherwise not
                  as described. Advice about your legal rights is available from
                  the Citizens Advice website. Nothing in these Terms of Service
                  will affect your rights at law.
                </li>
                <li>
                  <b>Limitation of Liability</b>. To the extent we are allowed
                  under applicable law to limit our liability to you, then our
                  liability to you arising under or in connection with the
                  Service and these Terms of Service, whether in contract, tort
                  (including negligence), breach of statutory duty or otherwise,
                  is limited to the greater of: (a) the total Service Fee you
                  have paid to us in connection with the incident or incidents
                  to which the relevant claim relates; or (b) £100.
                </li>
                <li>
                  <b> Losses we are not liable for</b>. To the extent permitted
                  by law, we will not be liable to you under or in connection
                  with the Service and these Terms of Service, whether in
                  contract, tort (including negligence), breach of statutory
                  duty or otherwise, for any: (i) direct or indirect loss of
                  revenues, data, profits, contracts, use, opportunity, business
                  or anticipated savings; (ii) loss of goodwill or reputation;
                  (iii) unforeseeable losses, meaning losses where it was not
                  obvious before you made your Offer that those losses would or
                  might be suffered by you, and nothing you said to us before we
                  accepted your Offer meant we should have expected those losses
                  to be suffered; or (iv) losses you could have avoided by
                  taking reasonable action.
                </li>
                <li>
                  <b>Liability for Restaurants, Waiters and Menu Items</b>. You
                  acknowledge and agree that to the fullest extent permitted by
                  law, Alacart is not responsible for, and will not be liable
                  for, any loss or damage caused by or in connection with any
                  Menu Items, advice, information, instructions, materials or
                  content provided to you by a Restaurant or Waiter. Your sole
                  recourse in connection with any Menu Items, advice,
                  information, instructions, materials or content provided to
                  you by a Restaurant or Waiter is against the relevant
                  Restaurant and/or Waiter.
                </li>
                <li>
                  <b>Events Outside our Control</b>. We will not be liable for
                  any failure or delay resulting from any condition beyond our
                  reasonable control, including but not limited to governmental
                  action or acts of terrorism, pandemics, epidemics, earthquake,
                  fire, flood or other acts of God, labour conditions, power
                  failures, and Internet disturbances.
                </li>
                <li>
                  <b>Disclaimer</b>: To the extent permitted by law, the
                  Service, Service Content and any information, materials,
                  content or advice given on or through the Service or Service
                  Content, whether written, stated or implied, is for
                  information purposes only and is provided “as is” and “as
                  available” and without warranty, undertaking, representation
                  or condition of any kind by Alacart. You agree that you must
                  evaluate, and bear all risks associated with, the use of any
                  information, content or advice appearing on or through the
                  Service or Service Content, including any reliance on the
                  accuracy, completeness, or usefulness of such content.
                </li>
                <li>
                  <b>Availability of the Platform</b>: While we do our best to
                  avoid interruptions or unavailability of the Service, we
                  cannot guarantee that the Service or Service Content will
                  always be unavailable, uninterrupted or error free.
                </li>
                <li>
                  <b>Viruses and Harmful Code</b>: We do not guarantee that the
                  Service or Service Content will be secure or free from harmful
                  code, viruses or bugs.
                </li>
                <li>
                  <b>Third Party Material</b>. The Service may contain third
                  party content or information, including information in
                  relation to Menu Items and prices for Menu Items, which are
                  provided to Alacart by Restaurants. We exclude any warranties,
                  undertakings, conditions or representations (either express or
                  implied) to the fullest extent permitted under applicable law,
                  that any such information or content is accurate, true, safe,
                  or approved or verified by any regulatory body or authority.
                  Under no circumstances will Alacart be liable in any way for
                  any information, advice, content or materials of any third
                  parties, including, but not limited to, for any errors or
                  omissions in any content, or for any loss or damage of any
                  kind incurred as a result of the use of any such content. You
                  acknowledge that Alacart does not pre-screen or independently
                  verify content appearing on or through the Service or Service
                  Content, but that Alacart and its designees will have the
                  right (but not the obligation) in their sole discretion to
                  refuse or remove any content that is available via the
                  Service.
                </li>
                <li>
                  <b>Third Party Websites</b>. The Service may provide links or
                  other access to other sites and resources on the Internet.
                  Alacart has no control over such sites and resources and
                  Alacart is not responsible for and does not endorse such sites
                  and resources. You further acknowledge and agree that Alacart
                  will not be responsible or liable, directly or indirectly, for
                  any damage or loss caused or alleged to be caused by or in
                  connection with use of or reliance on any content, events,
                  goods or services available on or through any such site or
                  resource. Any dealings you have with third parties found while
                  using the Service are between you and the third party, and you
                  agree that Alacart is not liable for any loss or claim that
                  you may have against any such third party.
                </li>
              </ul>
            </li>
            <li>
              <b>Termination and Suspension </b>
              <ul>
                <li>
                  <b>Our Rights to Suspend, Block and Terminate</b>. You agree
                  that Alacart, in its sole discretion, may suspend or terminate
                  your account (or any part thereof) or use of the Service, and
                  block you (including but not limited to blocking your mobile
                  phone number(s), credit card(s), WhatsApp account(s), phone
                  device(s), IP address(s), and email address(es)) from
                  accessing and using the Service now and in future, if we
                  believe that you have violated or acted inconsistently with
                  the letter or spirit of these Terms of Service. Any suspected
                  fraudulent, abusive or illegal activity that may be grounds
                  for suspension, blocking or termination of your use of
                  Service, may be referred to appropriate law enforcement
                  authorities. Alacart may also in its sole discretion and at
                  any time discontinue providing the Service, or any part
                  thereof, or may remove and discard any content within the
                  Service or Service Content, with or without notice. Upon such
                  termination of your access to the Service, you acknowledge and
                  agree that Alacart may immediately deactivate or delete your
                  account and all related information and files in your account
                  and/or bar any further access to such files or the Service or
                  Service Content. Further, you agree that Alacart will not be
                  liable to you or any third party for any termination of your
                  access to the Service or Service Content.
                </li>
                <li>
                  <b>Abusive Behaviour</b>. We may also suspend or close your
                  account, and block you (including but not limited to blocking
                  your mobile phone number(s), credit card(s), WhatsApp
                  account(s), IP address(s), phone device(s) and email
                  address(es)) from accessing and using the Service now and in
                  future, if in our opinion you are abusing our Service (for
                  example, by applying for refunds or credit to which we do not
                  consider you are entitled, making repeated unreasonable
                  complaints, mistreating our staff or any Waiters or other
                  Restaurant staff, or for any other good reason).
                </li>
              </ul>
            </li>
            <li>
              <b>General</b>
              <ul>
                <li>
                  <b>Electronic Communications</b>. You agree to receive
                  electronically all communications and notices that we provide
                  in connection with the Service. We may be required to provide
                  you with communications in written form, which you agree to
                  receive electronically instead of in paper form, including via
                  the Chatbot, or by posting notice or communicating with you
                  via our website. Communications include these Terms of Service
                  and any and all other agreements or policies to which you must
                  agree in order to use the Service, including updates to those
                  agreements and policies, payment authorisations and
                  transaction receipts or confirmations and customer service
                  matters.
                </li>
                <li>
                  <b>General Terms</b>. These Terms of Service contain the
                  entire agreement between us and you with respect to their
                  subject matter. A person who is not a party to these Terms of
                  Service has no rights under them, whether under the Contracts
                  (Rights of Third Parties) Act or otherwise. If either we or
                  you have any right to enforce these Terms of Service against
                  the other, that right will not be lost even if the person who
                  has the right delays enforcing it, or waives their right to
                  enforce it in any instance. If a court or other authority
                  decides that any part of these Terms of Service is illegal or
                  ineffective, the rest of the terms will be unaffected and will
                  remain in force.
                </li>
                <li>
                  <b>Applicable Law and Jurisdiction</b>. These Terms of
                  Service, and any disputes arising out of or in connection with
                  them (including non-contractual disputes), are governed by
                  English law and you can bring legal proceedings in relation to
                  our Service or these Service Terms in the English courts. If
                  you live in Scotland you can bring legal proceedings in
                  respect of our Service or these Service Terms in either the
                  Scottish or the English courts. If you live in Northern
                  Ireland you can bring legal proceedings in respect of our
                  Service or these Service Terms in either the Northern Irish or
                  the English courts.
                </li>
                <li>
                  <b>Contact Us</b>. If you have any questions, comments or
                  complaints, please contact us at{" "}
                  <a href="mailto:info@alacart.me">info@alacart.me</a>.
                </li>
              </ul>
            </li>
          </ol>
        </Content>
      </Container>
    </>
  );
};
