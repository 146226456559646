import { Container } from "../../components/container";
import { Typography } from "../../components/typography";
import { Input } from "../../components/input";
import { Root, Row, CreditCardIcon, Logo } from "./styles";
import Visa from "../../assets/images/visa.png";
import MasterCard from "../../assets/images/mastercard.png";
import Amex from "../../assets/images/amex.png";
import Discover from "../../assets/images/discover.png";
import { Button } from "../../components/button";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useWaiterMeMutation,
  useWaiterUpdateBankAccountMutation,
} from "../../common/store";
import { ErrorHint } from "../../components/error-hint";
import React, { useEffect, useState } from "react";
import { NotificationBox } from "../../components/notification-box";
import { getOnlyKeys, objectsDifference } from "../../common/utils";
import { isEmpty, get } from "lodash";
import { BackToMessenger } from "../../components/back-to-messenger";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";

export const WaiterBankDetailsContainer = () => {
  useReportPageLoadMetrics();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPrevValuesNotChanged, setIsPrevValuesNotChanged] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [updateBankAccount] = useWaiterUpdateBankAccountMutation();
  const [getMe] = useWaiterMeMutation();
  const [userData, setUserData] = useState(null);

  const {
    getFieldProps,
    errors,
    setErrors,
    handleSubmit,
    setFieldValue,
    touched,
    isValid,
    setValues,
    values,
    validateForm,
  } = useFormik({
    initialValues: {
      accountHolderName: "",
      sortCode: "",
      accountNumber: "",
      postCode: "",
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      accountHolderName: yup.string().required("Required field"),
      sortCode: yup.string().required("Required field"),
      accountNumber: yup.string().required("Required field"),
      postCode: yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      updateBankAccount(values)
        .unwrap()
        .then(() => {
          setShowSuccessMessage(true);
        })
        .catch((err) => {
          if (err?.status === 400) {
            setErrors(err?.data?.message);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
  });

  useEffect(() => {
    getMe({})
      .unwrap()
      .then((res: any) => {
        const accountHolderName = get(res, "accountHolderName");
        const fullName = get(res, "fullName");
        setValues(res).then(() => {
          setUserData(res);
          if (!accountHolderName && fullName) {
            setFieldValue("accountHolderName", fullName);
          }
          validateForm().then(() => {});
        });
      });
  }, []);

  useEffect(() => {
    const compare = [
      "accountHolderName",
      "sortCode",
      "accountNumber",
      "postCode",
    ];
    const difference = objectsDifference(userData, values, compare);
    const userValues = getOnlyKeys(userData, compare);
    setIsPrevValuesNotChanged(
      isEmpty(userValues) ? false : Object.values(difference).length === 0
    );
  }, [userData, values]);

  if (showSuccessMessage) {
    return (
      <Root>
        <Container direction="column">
          <Logo />
        </Container>
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="8vh"
        >
          <NotificationBox content="Thank you, you have successfully added bank information" />
        </Container>

        <Container mt="8px">
          <BackToMessenger />
        </Container>
      </Root>
    );
  }

  return (
    <Root>
      <Container direction="column">
        <Logo />
      </Container>
      <Container direction="column" mt="24px">
        <Typography variant="large" color="#fff">
          Enter your bank account information to receive payments:
        </Typography>
      </Container>
      <Container direction="column" mt="28px">
        <Typography
          variant="x-large"
          fontFamily="Matter, sans-serif"
          fontWeight="bold"
          color="#fff"
        >
          Beneficiary Bank Details
        </Typography>
      </Container>
      <Container direction="column" mt="24px">
        <Input label="Full name" {...getFieldProps("accountHolderName")} />
        <ErrorHint
          error={touched.accountHolderName && errors.accountHolderName}
        />
      </Container>
      <Container direction="column" mt="8px">
        <Input label="Account number" {...getFieldProps("accountNumber")} />
        <ErrorHint error={touched.accountNumber && errors.accountNumber} />
      </Container>
      <Container direction="column" mt="8px">
        <Input label="Sort code" {...getFieldProps("sortCode")} />
        <ErrorHint error={touched.sortCode && errors.sortCode} />
      </Container>
      <Container direction="column" mt="8px">
        <Input label="Postal code" {...getFieldProps("postCode")} />
        <ErrorHint error={touched.postCode && errors.postCode} />
      </Container>
      <Container direction="column" mt="24px">
        <Button
          disabled={!isValid || isSubmitting || isPrevValuesNotChanged}
          onClick={() => {
            handleSubmit();
          }}
        >
          Complete
        </Button>
      </Container>
      <Container direction="column" mt="24px">
        <Row>
          <CreditCardIcon src={Visa} />
          <CreditCardIcon src={MasterCard} />
          <CreditCardIcon src={Discover} />
          <CreditCardIcon src={Amex} />
        </Row>
      </Container>
      <Container direction="column" mt="48px" textAlign="center">
        <Typography variant="small" color="#ffffff">
          We accept payment by Visa, Mastercard, Discover, and American Express.
          All payments are securely processed through Stripe (visit
          https://www.stripe.com for more information). Alacart does not store
          any payment details. PCI-DSS encryption requirements help ensure the
          secure handling of credit card information by Alacart and its service
          providers.
        </Typography>
      </Container>
    </Root>
  );
};
