import React from "react";
import { Container } from "../../components/container";
import { Typography } from "../../components/typography";
import { Root, Logo, BackButton } from "./styles";
import { NotificationBox } from "../../components/notification-box";
import { Form } from "./form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STATE_AUTO_CHARGE_ERROR, STRIPE_KEY } from "../../common/constants";
import { IconBackBlack } from "../../components/icon";
import { useSearchParams } from "../../common/hooks/useSearchParams";
const stripePromise = loadStripe(STRIPE_KEY);

export const ClientManageCardsNewContainer = () => {
  const { state, brand, last4 } = useSearchParams();

  const invalidCard = [(brand || "").toUpperCase(), last4]
    .filter(Boolean)
    .join(" ");

  return (
    <Root>
      <Container>
        <BackButton
          onClick={() => {
            window.history.back();
          }}
        >
          <IconBackBlack />
        </BackButton>
      </Container>
      <Container direction="column" alignItems="center">
        <Logo />
      </Container>

      <>
        {state === STATE_AUTO_CHARGE_ERROR ? (
          <>
            <Container direction="column" mt="24px">
              <NotificationBox
                title="Invalid payment method"
                content={
                  <Typography variant="x-large">
                    We are unable to authenticate your payment method
                    {invalidCard ? <b> {invalidCard}</b> : ""}. Please choose a
                    different payment method and try again.
                  </Typography>
                }
              />
            </Container>
            <Container direction="column" mt="24px">
              <Typography variant="large" color="#fff" textAlign="center">
                Choose a payment method
              </Typography>
            </Container>
          </>
        ) : (
          <Container direction="column" mt="24px">
            <Typography variant="large" color="#fff" textAlign="center">
              Manage payment method
            </Typography>
          </Container>
        )}
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [
              {
                family: "Matter",
                src: 'local("Matter"), local("Matter"), url(/fonts/matter/Matter-SemiBold.woff2) format("truetype")',
              },
            ],
          }}
        >
          <Form />
        </Elements>
      </>
    </Root>
  );
};
