import styled from "styled-components";

interface RootProps {
  fontFamily?: string;
  variant: string;
  fontWeight?: string;
  textAlign?: string;
  textDecoration?: string;
  color?: string;
  as?: "p" | "h1" | "h2" | "h3" | "h4" | "span" | "div";

  onClick?: () => void;
}

export const Root = styled.p<RootProps>`
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`}
  ${(p) => p.variant === "normal" && `font-size: 12px;`}
  ${(p) => p.variant === "large" && `font-size: 14px;`}
  ${(p) => p.variant === "x-large" && `font-size: 16px;`}
  ${(p) => p.variant === "xx-large" && `font-size: 20px;`}
  ${(p) => p.variant === "small" && `font-size: 10px;`}
  ${(p) => p.fontWeight === "bold" && `font-weight: 600;`}
  ${(p) => p.color && `color: ${p.color};`}
  ${(p) => p.textAlign && `text-align: ${p.textAlign};`}
  ${(p) => p.textDecoration && `text-decoration: ${p.textDecoration};`}
  margin-top: 0;
  margin-bottom: 0;
`;
