import { useWaiterMeMutation } from "../store";
import { useUrlToken } from "../hooks/useUrlToken";
import { useEffect } from "react";
import { LoadingLayout } from "../../layouts/loading-layout";
import { AUTH_TOKEN } from "../constants";

export const withWaiterAuthorized =
  (ComposedComponent: any) => (props: any) => {
    const token = useUrlToken();
    const localToken = localStorage.getItem(AUTH_TOKEN);

    const [getMe, { data, isLoading }] = useWaiterMeMutation();

    useEffect(() => {
      if (token || localToken) {
        getMe({});
      }
    }, [token, localToken]);

    if (isLoading) {
      return <LoadingLayout />;
    }

    if (!data) {
      return null;
    }

    return <ComposedComponent {...props} />;
  };
