import { Button } from "../button";
import React, { FC } from "react";
import { useUrlMsgr } from "../../common/hooks/useUrlMsgr";
import { closeTab, openSmsApp, openWhatsappApp } from "../../common/utils";
import { MESSENGERS } from "../../common/constants";
import { get } from "lodash";

interface Props {
  type?: "link" | "primary";
}

export const BackToMessenger: FC<Props> = ({ type = "link" }) => {
  const messenger = useUrlMsgr();

  const handleBack = () => {
    if (messenger === "sms") {
      openSmsApp();
    } else if (messenger === "whatsapp") {
      openWhatsappApp();
    } else {
      closeTab();
    }
  };

  if (!messenger) return null;

  return (
    <Button type={type} onClick={handleBack}>
      Back to {get(MESSENGERS, messenger, "Messenger")}
    </Button>
  );
};
