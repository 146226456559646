import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  min-height: 100vh;
`;

interface Props {
  pt?: string;
}

export const InnerContainer = styled.div<Props>`
  ${(p) => p.pt && `padding-top: ${p.pt};`};
  height: 100%;
  padding-bottom: 20px;
  max-width: 768px;
  margin: 0 auto;
`;
