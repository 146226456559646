import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";

export const waiterApi = createApi({
  reducerPath: "waiterApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    waiterMe: builder.mutation<{ id: number; fullName: string }, any>({
      query: (params) => ({
        url: "/api/v1/waiters/me",
        method: "GET",
        params,
      }),
    }),
    waiterUpdateBankAccount: builder.mutation<any, Record<string, any>>({
      query: (body) => ({
        url: "/api/v1/waiters/bank-account",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const { useWaiterMeMutation, useWaiterUpdateBankAccountMutation } =
  waiterApi;
