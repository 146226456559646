import { FC } from "react";
import { Root, InnerContainer } from "./styles";

interface Props {
  children: React.ReactNode;
}

export const BaseLayout: FC<Props> = ({ children }) => {
  return (
    <Root>
      <InnerContainer>{children}</InnerContainer>
    </Root>
  );
};
