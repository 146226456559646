import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";

interface IClientPaymentMethods {
  id: number;
  object: string;
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

interface IOrder {
  id: number;
  cardId: string;
  restaurantId: number;
  totalDue: number;
  totalTax: number;
  totalTips: number;
  totalExclusiveTax: number;
  totalInclusiveTax: number;
  totalItems: number;
  totalOtherCharges: number;
  totalPaid: number;
  totalServiceCharges: number;
  subTotal: number;
  totalDiscounts: number;
  total: number;
  items: IOrderItem[];
  clientStatus: string;

  isPaid: boolean;
  status: string;

  createdAt: string;
  updatedAt: string;

  autoChargeError: string;
}

interface IOrderItem {
  id: number;
  name: string;
  comment: string;
  omnivoreId: string;
  price: number;
  quantity: number;
}

const TAG = "CLIENT";
const ID = "PAYMENT_METHODS";

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [TAG],
  endpoints: (builder) => ({
    clientMe: builder.mutation<
      { id: number; fullName: string; isRegisterCompleted: boolean },
      any
    >({
      query: (params) => ({
        url: "/api/v1/clients/me",
        method: "GET",
        params,
      }),
    }),
    getClientMe: builder.query<any, any>({
      query: (params) => ({
        url: "/api/v1/clients/me",
        method: "GET",
        params,
      }),
    }),
    updateClientMe: builder.mutation<{ fullName: string; postalCode }, any>({
      query: (body) => ({
        url: "/api/v1/clients/me",
        method: "PUT",
        body,
      }),
    }),
    getClientPaymentMethods: builder.query<
      { results: IClientPaymentMethods[] },
      any
    >({
      query: (params) => ({
        url: "/api/v1/clients/payment-methods",
        method: "GET",
        params,
      }),
      providesTags: [{ type: TAG, id: ID }],
    }),
    createClientPaymentMethods: builder.mutation<any, Record<string, string>>({
      query: (body) => ({
        url: "/api/v1/clients/payment-methods",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),
    deleteClientPaymentMethods: builder.mutation<any, Record<string, string>>({
      query: ({ id, ...params }) => ({
        url: `/api/v1/clients/payment-methods/${id}`,
        method: "DELETE",
        params,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),

    getDefaultClientPaymentMethod: builder.mutation<
      any,
      Record<string, string>
    >({
      query: ({ id, ...params }) => ({
        url: `/api/v1/clients/payment-methods/default`,
        method: "GET",
        params,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),

    setDefaultClientPaymentMethods: builder.mutation<
      any,
      Record<string, string>
    >({
      query: ({ id, ...params }) => ({
        url: `/api/v1/clients/payment-methods/${id}/default`,
        method: "PATCH",
        params,
      }),
      invalidatesTags: [{ type: TAG, id: ID }],
    }),

    getClientOrder: builder.query<IOrder, any>({
      query: ({ id, ...params }) => ({
        url: `/api/v1/orders/client-order/${id}`,
        method: "GET",
        params,
      }),
    }),

    payOrder: builder.mutation<any, Record<string, string>>({
      query: ({ id }) => ({
        url: `/api/v1/orders/${id}/pay`,
        method: "POST",
      }),
    }),

    requestFinal: builder.mutation<any, Record<string, string>>({
      query: ({ id }) => ({
        url: `/api/v1/orders/${id}/final`,
        method: "POST",
      }),
    }),

    getStripeClientSecret: builder.mutation<{ clientSecret: string }, any>({
      query: () => ({
        url: `/api/v1/clients/payment-methods/stripe-secret`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useClientMeMutation,
  useGetClientMeQuery,
  useGetDefaultClientPaymentMethodMutation,
  useUpdateClientMeMutation,
  useGetClientPaymentMethodsQuery,
  useCreateClientPaymentMethodsMutation,
  useDeleteClientPaymentMethodsMutation,
  useSetDefaultClientPaymentMethodsMutation,
  usePayOrderMutation,
  useGetStripeClientSecretMutation,
  useGetClientOrderQuery,
  useRequestFinalMutation,
} = clientApi;
