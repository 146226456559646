import styled from "styled-components";

export const Root = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FieldWrapper = styled.div<{ width?: string }>`
  width: ${(p) => (p.width ? p.width : "100%")};
  min-height: 38px;
`;
export const FieldLabel = styled.label`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  font-family: "Matter", sans-serif;
  color: #fff;
`;

export const FieldInput = styled.input`
  display: block;
  border: none;
  background: transparent;
  color: #ffffff;
  border-radius: 8px;
  padding: 2px 0;
  outline: none;
  font-size: 16px;
  font-family: "Matter", sans-serif;
  font-weight: 600;

  &::placeholder {
    color: rgba(255, 255, 255, 0.64);
  }
`;

export const StyledLabel = styled.label`
  width: 100%;
  font-family: "Matter", sans-serif;
  color: white;
  font-size: 14px;
`;

export const StyledInput = styled.input`
  margin-top: 4px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  border: none;
  padding: 16px;
  outline: none;
  font-family: "Matter", sans-serif;
  font-weight: 600;
  display: block;
`;

export const QuestionWrap = styled.div``;
