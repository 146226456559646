import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Modal from "react-modal";
import { Provider as StoreProvider } from "react-redux";
import TagManager from "react-gtm-module";
import { store } from "./common/store";
import { GTM_ID } from "./common/constants";
import { SegmentProvider } from "./components/segment";

Modal.setAppElement("#modal-root");

TagManager.initialize({
  gtmId: GTM_ID,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SegmentProvider>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </SegmentProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
