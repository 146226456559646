import styled from "styled-components";

interface RootProps {
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  textAlign?: string;
  width?: string;
  bgColor?: string;
}

export const Root = styled.div<RootProps>`
  width: ${(p) => (p.width ? p.width : "100%")};
  ${(p) => p.mt && `margin-top: ${p.mt}`};
  ${(p) => p.mb && `margin-bottom: ${p.mb}`};
  ${(p) => p.ml && `margin-left: ${p.ml}`};
  ${(p) => p.mr && `margin-right: ${p.mr}`};
  ${(p) => p.pt && `padding-top: ${p.pt}`};
  ${(p) => p.pb && `padding-bottom: ${p.pb}`};
  ${(p) => p.pl && `padding-left: ${p.pl}`};
  ${(p) => p.pr && `padding-right: ${p.pr}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.bgColor && `background-color: ${p.bgColor}`};
`;
