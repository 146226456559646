import { FC } from "react";
import { Typography } from "../typography";
import { Root } from "./styles";
import { Box } from "../box";

interface Props {
  title?: string;
  content: any;
}

export const NotificationBox: FC<Props> = ({ title, content }) => {
  return (
    <Root>
      {title && (
        <Box mb="8px">
          <Typography
            variant="xx-large"
            fontFamily="Matter, sans-serif"
            fontWeight="bold"
          >
            {title}
          </Typography>
        </Box>
      )}

      <Typography variant="large" textAlign="center">
        {content}
      </Typography>
    </Root>
  );
};
