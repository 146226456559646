import { combineReducers } from "redux";
import { clientApi, restaurantApi, waiterApi, orderApi, cardApi } from "./api";

export const rootReducer = combineReducers({
  [clientApi.reducerPath]: clientApi.reducer,
  [waiterApi.reducerPath]: waiterApi.reducer,
  [restaurantApi.reducerPath]: restaurantApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [cardApi.reducerPath]: cardApi.reducer,
});
