import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WaiterSignUp } from "./pages/waiters/waiter-sign-up";
import { ClientSignUp } from "./pages/clients/client-sign-up";
import { ClientWaitForWaiter } from "./pages/clients/client-wait-for-waiter";
import { ClientManageCards } from "./pages/clients/client-manage-cards";
import { ClientAddNewCard } from "./pages/clients/client-add-new-card";
import { WaiterBankDetails } from "./pages/waiters/waiter-bank-details";
import { ClientBill } from "./pages/clients/client-bill";
import { RedirectFromCard } from "./components/redirect-card";
import { EmptyPage } from "./components/empty-page";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { TermsAndConditions } from "./pages/terms-and-conditions";
import { ClientWelcome } from "./pages/clients/client-welcome";
import { ClientCode } from "./pages/clients/client-code";
import { RootBoundary } from "./components/root-boundary/RootBoundary";

const ErrorPage = () => {
  throw new Error("Error example");
};

const router = createBrowserRouter([
  {
    path: "/waiter-sign-up",
    element: <WaiterSignUp />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/waiter-bank-details",
    element: <WaiterBankDetails />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/cards/:type/:cardId",
    element: <RedirectFromCard />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-sign-up",
    element: <ClientSignUp />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-code/:orderSid",
    element: <ClientCode />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-welcome",
    element: <ClientWelcome />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-wait-for-waiter",
    element: <ClientWaitForWaiter />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-manage-cards",
    element: <ClientManageCards />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-add-new-card",
    element: <ClientAddNewCard />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/client-bill/:id",
    element: <ClientBill />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    errorElement: <RootBoundary />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
    errorElement: <RootBoundary />,
  },
  {
    path: "*",
    element: <EmptyPage content="" />,
    errorElement: <RootBoundary />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
