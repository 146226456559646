import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png";

export const Root = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})`
  margin-top: 10px;
  width: 220px;
`;
