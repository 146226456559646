import { FC } from "react";
import { Root, InnerContainer } from "./styles";

interface Props {
  children: React.ReactNode;
  innerPaddingTop?: string;
}

export const BlankLayout: FC<Props> = ({ children, innerPaddingTop }) => {
  return (
    <Root>
      <InnerContainer pt={innerPaddingTop}>{children}</InnerContainer>
    </Root>
  );
};
