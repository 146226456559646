import styled from "styled-components";

export const PageTitle = styled.h1`
  font-family: "Matter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 8px 0;
`;

export const SubTitle = styled.h2`
  font-family: "Matter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212322;
  margin: 0 0 8px 0;
`;

export const Content = styled.div`
  font-family: "Matter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;

  a {
    color: #212322;
  }

  & > p {
    margin: 0 0 8px 0;

    &:last-child {
      margin: 0;
    }
  }

  ol ul {
    list-style-type: lower-alpha;
    padding-inline-start: 30px;
    margin-top: 8px;
  }

  li {
    margin-bottom: 4px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    th,
    td {
      vertical-align: top;
      border: 1px solid #e0e0e0;
      padding: 8px;
      width: 50%;
    }
  }
`;

export const BackButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

export const BackIcon = styled.img`
  width: 24px;
`;
