import React, { FC, useEffect, useState } from "react";
import { Root, RootContainer } from "./styles";
import ApplyPayIcon from "../../assets/images/apple-pay.svg";
import GooglePayIcon from "../../assets/images/google-pay.svg";
import { Radio } from "../radio";
import { useStripe } from "@stripe/react-stripe-js";
import { get } from "lodash";
import clsx from "clsx";

interface Props {
  checked?: boolean;
  onClick?: () => void;
  onSuccess?: (pm) => void;
  onSuccessWalletType?: (walletType) => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const RadioPayButton: FC<Props> = ({
  checked,
  onClick,
  onSuccess,
  onSuccessWalletType,
  onCancel,
  disabled,
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>();
  const [canMakePayment, setCanMakePayment] = useState(null);

  const isApplePayAvailable = get(canMakePayment, "applePay", false);
  const isGooglePayAvailable = get(canMakePayment, "googlePay", false);

  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "Alacart",
        amount: 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: false,
      disableWallets: ["link"],
    });

    pr.canMakePayment()
      .then((result) => {
        setCanMakePayment(result);
        if (result) {
          setPaymentRequest(pr);
          pr.on("paymentmethod", (ev) => {
            const paymentMethodId = get(ev, "paymentMethod.id", "");
            ev.complete("success");
            onSuccess && onSuccess(paymentMethodId);
            onSuccessWalletType &&
              onSuccessWalletType(
                isApplePayAvailable ? "apple_pay" : "google_pay"
              );
          });

          //@ts-ignore
          pr.on("cancel", (ev) => {
            onCancel && onCancel();
          });
        }
      })
      .catch((err) => {
        //log errors, retry, or abort here depending on your use case
      });
  }, [stripe]);

  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    onClick && onClick();
    if (paymentRequest) {
      paymentRequest.show();
    }
  };

  if (!isApplePayAvailable && !isGooglePayAvailable) {
    return null;
  }

  return (
    <Root
      className={clsx({
        disabled: disabled,
      })}
    >
      <RootContainer onClick={handleOnClick}>
        <Radio checked={checked} />
        {isApplePayAvailable && (
          <img src={ApplyPayIcon} height={24} alt="Apple pay" />
        )}

        {isGooglePayAvailable && (
          <img src={GooglePayIcon} height={24} alt="Google pay" />
        )}
      </RootContainer>
    </Root>
  );
};
