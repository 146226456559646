import { FC } from "react";
import { Root, Checkbox, CheckboxContainer, TextContainer } from "./styles";

interface Props {
  id: string;
  children: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
}

export const CheckboxButton: FC<Props> = ({
  id,
  children,
  onChange,
  isChecked,
}) => {
  return (
    <Root htmlFor={id} isChecked={isChecked}>
      <CheckboxContainer>
        <Checkbox id={id} checked={isChecked} onChange={onChange} />
      </CheckboxContainer>
      <TextContainer>{children}</TextContainer>
    </Root>
  );
};
