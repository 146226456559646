import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png";
import PageHeaderBg from "../../assets/images/page-header-bg.jpg";

export const Root = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: url(${PageHeaderBg}) #5b8f90;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 20px;
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})`
  margin-top: 10px;
  width: 145px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  font-family: "Matter", sans-serif;
  > div:not(:first-child) {
    text-align: right;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px dotted #212322;
  height: 1px;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  > div:not(:first-child) {
    text-align: right;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  font-family: "Matter", sans-serif;
  font-weight: bold;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  > div:first-child {
    font-size: 14px;
  }
  > div:last-child {
    gap: 8px;
    font-size: 18px;
  }
  > div:not(:first-child) {
    text-align: right;
  }
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  background: #5b8f90;
  border-radius: 50px;
  color: #ffffff;
  text-transform: uppercase;
`;

interface ColProps {
  width?: string;
  flex?: string;
}

export const Col = styled.div<ColProps>`
  width: ${(p) => p.width};
  flex: ${(p) => p.flex};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  .row-button:first-child {
    margin-right: 4px;
  }
  .row-button:last-child {
    margin-left: 4px;
  }
`;

export const ManagePaymentMethodButtonContainer = styled.div`
  padding: 14px;
  background: #f2f2f2;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #212322;
  text-align: center;
`;

export const ManagePaymentMethodButton = styled.button`
  font-family: "Garnett Semibold";
  background: #ffffff;
  border-radius: 8px;
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #212322;
  border: 0;
  margin-top: 16px;
  width: 100%;
`;
