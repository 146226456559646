import styled from "styled-components";

export const Root = styled.div`
  background-color: #212322;
  border-radius: 12px;
  margin-bottom: 8px;

  &.disabled {
    opacity: 0.48;
    cursor: not-allowed;
  }
`;
export const RootContainer = styled.button`
  width: 100%;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0 16px;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  gap: 8px;
`;
