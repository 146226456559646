import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const useSearchParams = () => {
  const [params, setParams] = useState<any>({});
  const { search } = useLocation();

  useMemo(() => {
    try {
      const searchParams = new URLSearchParams(search);
      setParams(Object.fromEntries(searchParams.entries()));
    } catch (e) {
      setParams({});
    }
  }, [search]);

  return params;
};
