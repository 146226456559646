import WifiIcon from "../../assets/images/wifi-icon.png";
import { Button } from "../../components/button";
import { Icon } from "../../components/icon";
import { Container } from "../../components/container";
import { Root, Logo } from "./styles";
import { Typography } from "../../components/typography";
import { NotificationBox } from "../../components/notification-box";

export const ClientWaitForWaiterContainer = () => {
  return (
    <Root>
      <Container
        flex-direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button type="transparent">
          <Icon width="15px" src={WifiIcon} /> Connect to WiFi
        </Button>
      </Container>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="20vh"
      >
        <Typography
          variant="xx-large"
          color="#ffffff"
          fontFamily="Matter, sans-serif"
          fontWeight="bold"
        >
          Welcome to
        </Typography>
        <Logo />
      </Container>
      <Container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt="8vh"
      >
        <NotificationBox
          title="This tab was not opened yet"
          content="Please wait a minute and your waiter will setup your table."
        />
      </Container>
    </Root>
  );
};
