import styled from "styled-components";

interface RootProps {
  direction?: "row" | "column";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around";
  alignItems?: "flex-start" | "flex-end" | "center";
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  p?: string;
  textAlign?: string;
  width?: string;
  maxWidth?: string;
  bgColor?: string;
  borderRadius?: string;
  minHeight?: string;
}

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: ${(p) => p.direction || "row"};
  justify-content: ${(p) => p.justifyContent || "initial"};
  align-items: ${(p) => p.alignItems || "initial"};
  padding: 0 20px;
  width: ${(p) => (p.width ? p.width : "100%")};
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : "100%")};
  ${(p) => p.mt && `margin-top: ${p.mt}`};
  ${(p) => p.mb && `margin-bottom: ${p.mb}`};
  ${(p) => p.ml && `margin-left: ${p.ml}`};
  ${(p) => p.mr && `margin-right: ${p.mr}`};
  ${(p) => p.pt && `padding-top: ${p.pt}`};
  ${(p) => p.pb && `padding-bottom: ${p.pb}`};
  ${(p) => p.pl && `padding-left: ${p.pl}`};
  ${(p) => p.pr && `padding-right: ${p.pr}`};
  ${(p) => p.p && `padding: ${p.p}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.bgColor && `background-color: ${p.bgColor}`};
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius}`};
  ${(p) => p.minHeight && `min-height: ${p.minHeight}`};
`;
