import styled from "styled-components";
import Check from "../../assets/images/check.png";

interface LabelProps {
  isChecked?: boolean;
}

export const Root = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Matter", sans-serif;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  border: none;
  background: #212322;
  color: white;
  font-size: 16px;
  box-sizing: border-box;
  transition: background-color 0.2s;
  &:active {
    background-color: #21232288;
  }
  ${(p) => p.isChecked && `background-color: #21232288;`};
`;

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 100%;
  appearance: none;
  position: static;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: 6px;
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 4px;
  }
  &:checked {
    &::before {
      background-color: #5b8f90;
      background-image: url(${Check});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
    }
  }
`;

export const CheckboxContainer = styled.div`
  position: relative;
  width: 16%;
`;
export const TextContainer = styled.div`
  padding-left: 16px;
  width: calc(84% - 24px);
`;
