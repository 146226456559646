import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AUTH_TOKEN } from "../constants";

export const useUrlToken = () => {
  const { search, pathname } = useLocation();
  const [token, setToken] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      const urlParams = new URLSearchParams(search);
      const token = urlParams.get("token");

      if (token) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.setItem(AUTH_TOKEN, token);

        setToken(token);
        // remove token from url
        urlParams.delete("token");
        navigate(pathname + "?" + urlParams.toString());
      }
    }
  }, [search]);

  return token;
};
