import { FC } from "react";
import Modal from "react-modal";
import { Container } from "../../container";
import { Typography } from "../../typography";
import { Button } from "../../button";
import { customStyles } from "../custom-styles";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onDelete: () => void;
}

export const DeleteCreditCardModal: FC<ModalProps> = ({
  isOpen,
  closeModal,
  onDelete,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Delete Credit Card"
    >
      <Container mb="34px" justifyContent="center">
        <Typography variant="x-large" fontFamily="Garnett Semibold">
          Delete credit card?
        </Typography>
      </Container>
      <Container mb="10px">
        <Button onClick={onDelete}>Confirm</Button>
      </Container>
      <Container>
        <Button onClick={closeModal} type="secondary">
          Cancel
        </Button>
      </Container>
    </Modal>
  );
};
