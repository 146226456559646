export const customStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(100vw - 40px)",
    maxWidth: "600px",
    borderRadius: "14px",
    border: "none",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
