import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateUrl } from "../../common/utils";
import { ROUTE_CLIENT_SIGN_UP } from "../../common/constants";
import { LoadingLayout } from "../../layouts/loading-layout";
import { useSegment } from "../segment";

export const RedirectFromCard: FC = () => {
  const segment = useSegment();
  const { type, cardId: card } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    segment.track("frontend_client_card_scanned", {
      cardId: card,
      type,
    });
  }, [type, card]);

  useEffect(() => {
    navigate(generateUrl(ROUTE_CLIENT_SIGN_UP, { type, card }));
  }, [type, card]);

  return <LoadingLayout />;
};
