import React, { FC } from "react";
import {
  PrimaryRoot,
  SecondaryRoot,
  ComingSoonRoot,
  LinkRoot,
  TranparentRoot,
} from "./styles";
import loaderSvg from "../../assets/images/loader.svg";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  type?:
    | "primary"
    | "secondary"
    | "coming-soon"
    | "link"
    | "transparent"
    | "submit";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

export const Button: FC<Props> = ({
  children,
  type,
  disabled,
  onClick,
  className,
  loading,
}) => {
  if (type === "secondary") {
    return (
      <SecondaryRoot
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </SecondaryRoot>
    );
  }
  if (type === "transparent") {
    return (
      <TranparentRoot
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </TranparentRoot>
    );
  }
  if (type === "coming-soon") {
    return (
      <ComingSoonRoot
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </ComingSoonRoot>
    );
  }
  if (type === "link") {
    return (
      <LinkRoot className={className} disabled={disabled} onClick={onClick}>
        {children}
      </LinkRoot>
    );
  }
  return (
    <PrimaryRoot
      className={clsx(className, {
        "-loading": loading,
        "-disabled": disabled,
      })}
      disabled={disabled || loading}
      onClick={onClick}
      type={type === "submit" ? type : undefined}
    >
      {loading ? (
        <img src={loaderSvg} alt="loader" className="loader" />
      ) : (
        children
      )}
    </PrimaryRoot>
  );
};
