import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png";

export const Root = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})`
  margin-top: 10px;
  width: 220px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CreditCardIcon = styled.img`
  height: 24px;
  margin-left: 4px;
  margin-right: 4px;
`;
