import React, { FC } from "react";
import { Container } from "../container";
import { RadioButton } from "../radio-button";
import {
  Root,
  RadioButtonContainer,
  ContentContainer,
  DeleteIconContainer,
  DeleteIconImg,
  CardTitle,
  Title,
} from "./styles";
import DeleteIcon from "../../assets/images/delete-icon.png";

interface Props {
  brand: string;
  fourDigits: string;
  id: string;
  name: string;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteClick: (id: string) => void;
  checked?: boolean;
  hideDelete?: boolean;
}

export const CreditCardCollapsed: FC<Props> = ({
  brand,
  fourDigits,
  id,
  name,
  onSelect,
  onDeleteClick,
  checked,
  hideDelete = false,
}) => {
  return (
    <Root>
      <RadioButtonContainer>
        <RadioButton
          onChange={onSelect}
          name={name}
          id={id}
          checked={checked}
        />
      </RadioButtonContainer>
      <ContentContainer>
        <Container direction="column">
          <Title>Card number</Title>
        </Container>
        <Container>
          <CardTitle>
            {brand} - **** {fourDigits}
          </CardTitle>
        </Container>
      </ContentContainer>
      {!hideDelete && (
        <DeleteIconContainer onClick={() => onDeleteClick(id)}>
          <DeleteIconImg src={DeleteIcon} />
        </DeleteIconContainer>
      )}
    </Root>
  );
};
