import React, { useEffect, useState } from "react";
import { Container } from "../../components/container";
import { Logo, Root } from "../WaiterBankDetails/styles";
import { Typography } from "../../components/typography";
import CardsList from "../../assets/images/cards-list.svg";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "../../common/constants";
import { Elements } from "@stripe/react-stripe-js";
import { Form } from "./form";
import { NotificationBox } from "../../components/notification-box";
import { BackToMessenger } from "../../components/back-to-messenger";
import { useClientMeMutation } from "../../common/store";
import { Loading } from "../../components/loading";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";
const stripePromise = loadStripe(STRIPE_KEY);

export const ClientRegisterContainer = () => {
  useReportPageLoadMetrics();
  const [isSuccess, setIsSuccess] = useState(false);
  const [getMe, { isLoading }] = useClientMeMutation();

  useEffect(() => {
    getMe({})
      .unwrap()
      .then((data) => {
        if (data?.isRegisterCompleted) {
          setIsSuccess(true);
        }
      });
  }, []);

  if (isSuccess && !isLoading) {
    return (
      <Root>
        <Container direction="column" alignItems="center">
          <Logo />
        </Container>
        <Container direction="column" mt="24px">
          <NotificationBox
            title="Your account was confirmed successfully."
            content={
              <Typography variant="x-large">
                Your payment method was added successfully. Please go back to
                SMS to set up your tab.
              </Typography>
            }
          />
        </Container>
        <Container mt="20px">
          <BackToMessenger type="primary" />
        </Container>
      </Root>
    );
  }

  if (isLoading) {
    return (
      <Root>
        <Container direction="column" alignItems="center">
          <Logo />
        </Container>
        <Container direction="column" mt="24px">
          <Loading />
        </Container>
      </Root>
    );
  }

  return (
    <Root>
      <Container direction="column" alignItems="center">
        <Logo />
      </Container>
      <Container direction="column" mt="24px">
        <Typography variant="x-large" color="#fff" textAlign="center">
          Fill in your information
        </Typography>
      </Container>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              family: "Matter",
              src: 'local("Matter"), local("Matter"), url(https://pay.alacart.me/fonts/matter/Matter-SemiBold.woff2) format("truetype")',
            },
          ],
        }}
      >
        <Form
          onSuccess={() => {
            setIsSuccess(true);
          }}
        />
      </Elements>
      <Container direction="column" mt="48px">
        <img src={CardsList} height={24} alt="Cards" />
      </Container>
      <Container direction="column" mt="8px" textAlign="center">
        <Typography variant="small" color="#ffffff">
          We accept payment by Visa, Mastercard, Discover, and American Express.
          All payments are securely processed through Stripe (visit
          https://www.stripe.com for more information). Alacart does not store
          any payment details. PCI-DSS encryption requirements help ensure the
          secure handling of credit card information by Alacart and its service
          providers.
        </Typography>
      </Container>
    </Root>
  );
};
