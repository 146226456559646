import SegmentContext from "./segment.context";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { SEGMENT_KEY } from "../../common/constants";

const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_KEY,
});

export const SegmentProvider = ({ children }) => {
  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};
