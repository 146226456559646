import { FC } from "react";
import { Root, InnerContainer } from "./styles";
import { Typography } from "../../components/typography";
import { Container } from "../../components/container";
import { Logo } from "../../features/ClientWaitForWaiter/styles";
import { NotificationBox } from "../../components/notification-box";

export const ForbiddenLayout: FC = () => {
  return (
    <Root>
      <InnerContainer>
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="20vh"
        >
          <Typography
            variant="xx-large"
            color="#ffffff"
            fontFamily="Matter, sans-serif"
            fontWeight="bold"
          >
            Welcome to
          </Typography>
          <Logo />
        </Container>
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="8vh"
        >
          <NotificationBox content="Link expired, please try again later" />
        </Container>
      </InnerContainer>
    </Root>
  );
};
