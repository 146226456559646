import { useEffect } from "react";
import { useSearchParams } from "./useSearchParams";

export const useUrlMsgr = () => {
  const { msgr } = useSearchParams();

  useEffect(() => {
    if (msgr) {
      localStorage.setItem("msgr", msgr);
    }
  }, [msgr]);

  return localStorage.getItem("msgr") || msgr;
};
