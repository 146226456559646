import { FC } from "react";
import Modal from "react-modal";
import { Container } from "../../container";
import { Typography } from "../../typography";
import { Button } from "../../button";
import { customStyles } from "../custom-styles";
import { copyTextToClipboard } from "../../../common/utils";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;

  wifiSsid?: string;
  wifiPassword?: string;
}

export const WifiModal: FC<ModalProps> = ({
  isOpen,
  closeModal,
  wifiSsid,
  wifiPassword,
}) => {
  const handleClickCopy = () => {
    copyTextToClipboard(wifiPassword);
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Connect to WiFi">
      <Container mb="34px" justifyContent="center">
        <Typography variant="x-large" fontFamily="Garnett Semibold">
          Connect to WiFi
        </Typography>
      </Container>
      <Container direction="column" mb="34px">
        <Typography variant="x-large">
          <b>SSID:</b> {wifiSsid}
        </Typography>
        <div>
          <Typography variant="x-large" as="span">
            <b>Password:</b> {wifiPassword ? wifiPassword : "No password"}
            {"   "}
          </Typography>
          {wifiPassword && (
            <Typography
              variant="large"
              textDecoration="underline"
              fontWeight={"500"}
              as="span"
              onClick={handleClickCopy}
            >
              Copy
            </Typography>
          )}
        </div>
      </Container>
      <Container>
        <Button onClick={closeModal} type="secondary">
          Cancel
        </Button>
      </Container>
    </Modal>
  );
};
