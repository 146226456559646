import { useGetClientMeQuery } from "../store";
import { useUrlToken } from "../hooks/useUrlToken";

import { LoadingLayout } from "../../layouts/loading-layout";
import { ForbiddenLayout } from "../../layouts/forbidden-layout";

export const withClientAuthorized =
  (ComposedComponent: any) => (props: any) => {
    useUrlToken();
    const { data, isLoading } = useGetClientMeQuery({});

    if (isLoading) {
      return <LoadingLayout />;
    }

    if (!data) {
      return <ForbiddenLayout />;
    }

    return <ComposedComponent {...props} />;
  };
