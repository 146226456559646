import React, { ComponentType } from "react";
import { BlankLayout } from "./blank-layout";

interface LayoutProps {
  innerPaddingTop?: string;
}

export const withBlankLayout =
  (ComposedComponent: ComponentType, layoutProps: LayoutProps = {}) =>
  (props: any) => {
    return (
      <BlankLayout {...layoutProps}>
        <ComposedComponent {...props} />
      </BlankLayout>
    );
  };
