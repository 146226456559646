import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png";
import CardBg from "../../assets/images/card-bg.png";

export const Root = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})`
  margin-top: 10px;
  width: 220px;
`;

export const CodeWrapper = styled.div`
  border-radius: 8px;
  background: url("${CardBg}") no-repeat;
  background-size: cover;
  min-height: 174px;
  max-width: 290px;
  margin: 41px auto 71px auto;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CodeTitle = styled.div`
  font-family: "Matter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 4px;
`;

export const Code = styled.div`
  font-family: "Matter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 10px;
  text-align: center;
  color: #ffffff;
`;
