import { FC } from "react";
import { Root } from "./styles";

interface Props {
  children: React.ReactNode;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  textAlign?: string;
  width?: string;
  bgColor?: string;
}

export const Box: FC<Props> = ({
  children,
  mt,
  mb,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  textAlign,
  width,
  bgColor,
}) => {
  return (
    <Root
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
      textAlign={textAlign}
      width={width}
      bgColor={bgColor}
    >
      {children}
    </Root>
  );
};
