import styled from "styled-components";

export const Root = styled.div``;

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  font-family: "Matter", sans-serif;
  color: white;
  font-size: 14px;
`;

export const StyledInput = styled.input`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  border: none;
  padding: 16px;
  outline: none;
  font-family: "Matter", sans-serif;
  font-weight: 600;
`;
