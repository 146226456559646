import loaderSvg from "../../assets/images/loader.svg";
import React, { FC } from "react";
import { Container, ContainerProps } from "../container";

interface LoadingProps extends ContainerProps {}

export const Loading: FC<LoadingProps> = ({ ...props }) => {
  return (
    <Container justifyContent="center" {...props}>
      <img src={loaderSvg} alt="loader" width="50" />
    </Container>
  );
};
