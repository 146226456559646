import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png";

export const Root = styled.div`
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img.attrs({
  src: LogoImg,
})`
  margin-top: 10px;
  width: 220px;
`;

export const BackButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  margin-top: -30px;
  margin-bottom: 24px;
`;

export const BackIcon = styled.img`
  width: 24px;
`;
