import { Container } from "../container";
import { Root, Logo } from "./styles";
import { Typography } from "../typography";
import { BaseLayout } from "../../layouts/base-layout";

export const EmptyPage = ({ content }) => {
  return (
    <BaseLayout>
      <Root>
        <Container
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt="20vh"
        >
          <Typography
            variant="xx-large"
            color="#ffffff"
            fontFamily="Matter, sans-serif"
            fontWeight="bold"
          >
            Welcome to
          </Typography>
          <Logo />
        </Container>
      </Root>
      {content}
    </BaseLayout>
  );
};
