import { Typography } from "../typography";
import { FC } from "react";
import { Root } from "./styles";

interface Props {
  error?: string | null | undefined | boolean;
}

export const ErrorHint: FC<Props> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Root>
      <Typography variant="normal" color="#9e2146">
        {error}
      </Typography>
    </Root>
  );
};
