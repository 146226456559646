import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../rtk.utils";

export const cardApi = createApi({
  reducerPath: "cardApi",
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    fetchCardInfo: builder.query<{ name: string; id: number }[], { id: any }>({
      query: ({ id, ...params }) => ({
        url: `/api/v1/cards/public/${id}`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useFetchCardInfoQuery } = cardApi;
