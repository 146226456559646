import React, { useEffect, useMemo, useState } from "react";
import {
  useCreateClientPaymentMethodsMutation,
  useDeleteClientPaymentMethodsMutation,
  useGetClientPaymentMethodsQuery,
  useGetStripeClientSecretMutation,
  usePayOrderMutation,
  useSetDefaultClientPaymentMethodsMutation,
} from "../../common/store";
import { get, map } from "lodash";
import { Container } from "../../components/container";
import { CreditCardCollapsed } from "../../components/credit-card-collapsed";
import { Button } from "../../components/button";
import { DeleteCreditCardModal } from "../../components/modals/delete-credit-card";
import { RadioCardButton } from "../../components/radio-card-button";
import { RadioPayButton } from "../../components/radio-pay-button";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Loading } from "../../components/loading";
import { useSearchParams } from "../../common/hooks/useSearchParams";
import {
  ROUTE_CLIENT_BILL,
  STATE_AUTO_CHARGE_ERROR,
  STRIPE_KEY,
} from "../../common/constants";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useSegment } from "../../components/segment";
import { useReportPageLoadMetrics } from "../../common/hooks/useReportPageLoadMetrics";
const stripePromise = loadStripe(STRIPE_KEY);

export const Form = () => {
  const [getClientSecret] = useGetStripeClientSecretMutation();
  useReportPageLoadMetrics();
  const [clientSecret, setClientSecret] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [cardValue, setCardValue] = useState(null);
  const [walletValue, setWalletValue] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [deleteCard, setDeleteCard] = useState<string>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const [deletePaymentMethod] = useDeleteClientPaymentMethodsMutation();
  const [createPaymentMethod] = useCreateClientPaymentMethodsMutation();
  const [setDefaultPaymentMethod] = useSetDefaultClientPaymentMethodsMutation();
  const {
    data,
    refetch: refetchPaymentMethods,
    isLoading,
  } = useGetClientPaymentMethodsQuery({});
  const [pay] = usePayOrderMutation();
  const paymentMethods = get(data, "results", []);
  const { state, order } = useSearchParams();
  const needPay = order && state === STATE_AUTO_CHARGE_ERROR;
  const segment = useSegment();

  useEffect(() => {
    handleGetClientSecret();
  }, []);

  const handleGetClientSecret = () => {
    getClientSecret({})
      .unwrap()
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleCloseDeleteModal = () => {
    setDeleteCard(null);
    setDeleteModalIsOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };

  const handleConfirm = () => {
    setIsSubmitting(true);

    if (selectedCard === "wallet") {
      createPaymentMethod({
        paymentMethodId: walletValue,
      })
        .unwrap()
        .catch((error) => {
          alert(error.message);
        })
        .then(() => {
          segment.track("frontend_client_manage_cards_add_wallet", {
            type: walletType,
          });

          handleGetClientSecret();
          handlePay();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (selectedCard === "card") {
      const cardElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: get(cardValue, "billingDetails", {
              address: {
                city: "United Kingdom",
                country: "UK",
                postal_code: "",
              },
            }),
          },
        })
        .then((res) => {
          const error = get(res, "error.message");
          if (error) {
            setIsSubmitting(false);
            alert(error);
            return;
          }
          setSelectedCard("");

          const paymentMethodId = get(res, "setupIntent.payment_method");
          cardElement.clear();
          cardExpiryElement.clear();
          cardCvcElement.clear();

          segment.track("frontend_client_manage_cards_add_wallet", {
            type: "card",
          });

          setDefaultPaymentMethod({ id: paymentMethodId })
            .unwrap()
            .then(() => {
              handleGetClientSecret();
              handlePay();
            })
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsSubmitting(false);
              refetchPaymentMethods();
            });
        })
        .catch((error) => {
          setIsSubmitting(false);
          alert(error.message);
        });
    } else if (selectedCard) {
      setDefaultPaymentMethod({ id: selectedCard })
        .unwrap()
        .then(() => {
          setIsSubmitting(false);
          handlePay();
        })
        .catch((error) => {
          setIsSubmitting(false);
          alert(error.message);
        });
    }
  };

  const handlePay = () => {
    if (!needPay) {
      return;
    }

    setIsSubmitting(true);
    pay({ id: order })
      .unwrap()
      .then(() => {
        navigate(ROUTE_CLIENT_BILL + "/" + order + "?state=success", {
          replace: true,
        });
        setIsSubmitting(false);
      })
      .catch((err) => {
        const errorCode = get(err, "data.error");
        const message = get(err, "data.message");
        if (errorCode === "authentication_required") {
          const clientSecret = get(err, "data.clientSecret");
          const pm = get(err, "data.paymentMethod");

          stripePromise.then((stripe) => {
            stripe
              .confirmCardPayment(clientSecret, {
                payment_method: pm,
              })
              .then((stripeJsResult) => {
                if (stripeJsResult.error) {
                  alert(stripeJsResult.error.message);
                } else if (
                  stripeJsResult.paymentIntent &&
                  stripeJsResult.paymentIntent.status === "succeeded"
                ) {
                  navigate(ROUTE_CLIENT_BILL + "/" + order + "?state=success");
                }
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          });
        } else if (message) {
          alert(message);
          setIsSubmitting(false);
        }
      });
  };

  useEffect(() => {
    const defaultSource = get(data, "defaultSource");
    setSelectedCard(defaultSource);
  }, [paymentMethods]);

  const confirmIsDisabled = useMemo(() => {
    if (selectedCard === "wallet" && walletValue === null) {
      return true;
    }
    if (selectedCard === "card" && cardValue === null) {
      return true;
    }
    return !selectedCard;
  }, [selectedCard, cardValue, walletValue]);

  const handleDelete = () => {
    deletePaymentMethod({ id: deleteCard })
      .unwrap()
      .then(() => {
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <Container direction="column" mt="24px">
        <Loading />
      </Container>
    );
  }

  const defaultSource = get(data, "defaultSource");

  return (
    <>
      <Container direction="column" mt="24px">
        <Container direction="column" mb="16px" p="0">
          <RadioPayButton
            checked={selectedCard === "wallet"}
            onClick={() => setSelectedCard("wallet")}
            onSuccess={(pm) => setWalletValue(pm)}
            onSuccessWalletType={(wt) => setWalletType(wt)}
            onCancel={() => setSelectedCard(defaultSource)}
          />
          {map(paymentMethods, (pm) => (
            <CreditCardCollapsed
              key={pm.id}
              name={"selected-credit-pm"}
              id={pm.id}
              brand={pm.card.brand.toUpperCase()}
              fourDigits={pm.card.last4}
              checked={selectedCard === pm.id}
              onSelect={() => setSelectedCard(pm.id)}
              hideDelete={paymentMethods.length === 1}
              onDeleteClick={(id) => {
                setDeleteCard(id);
                openDeleteModal();
              }}
            />
          ))}
          <RadioCardButton
            title="Add new credit card"
            clientSecret={clientSecret}
            checked={selectedCard === "card"}
            onClick={() => setSelectedCard("card")}
            onSuccess={(pm) => setCardValue(pm)}
          />
        </Container>
      </Container>
      <Container direction="column" mt="24px" mb="12px">
        <Button
          disabled={confirmIsDisabled}
          loading={isSubmitting}
          onClick={handleConfirm}
        >
          {state !== STATE_AUTO_CHARGE_ERROR
            ? "Confirm payment method"
            : "Confirm and pay"}
        </Button>
      </Container>

      <DeleteCreditCardModal
        isOpen={deleteModalIsOpen}
        closeModal={handleCloseDeleteModal}
        onDelete={handleDelete}
      />
    </>
  );
};
